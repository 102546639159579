.loaderMain {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 50px;
    height: 100vh;
    background: #21114D;
    backdrop-filter: blur(8px);
    background-image: url(/src/Images/bggShape.png);
    background-size: 100% 100%;
    background-position: center;
}
/* CIRCLE DOT LOADER */
.circle-loader {
    position: relative;
    width: auto;
    height: auto;
}
.circle-loader .loadCircle {
    height: 10px;
    width: 10px;
    background-color: #6341E0;
    border-radius: 50%;
    position: absolute;
    -webkit-animation: 0.8s opaque ease-in-out infinite both;
    animation: 0.8s opaque ease-in-out infinite both;
}
  
.circle-loader > .loadCircle:nth-child(1) {
    top: -25px;
    left: 0;
}
.circle-loader > .loadCircle:nth-child(2) {
    top: -17px;
    left: 17px;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}
.circle-loader > .loadCircle:nth-child(3) {
    top: 0;
    left: 25px;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}
.circle-loader > .loadCircle:nth-child(4) {
    top: 17px;
    left: 17px;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}
.circle-loader > .loadCircle:nth-child(5) {
    top: 25px;
    left: 0;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}
.circle-loader > .loadCircle:nth-child(6) {
    top: 17px;
    left: -17px;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}
.circle-loader > .loadCircle:nth-child(7) {
    top: 0;
    left: -25px;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}
.circle-loader > .loadCircle:nth-child(8) {
    top: -17px;
    left: -17px;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

@keyframes opaque {
    0% {
      opacity: 0.1;
    }
    40% {
      opacity: 1;
    }
    80% {
      opacity: 0.1;
    }
    100% {
      opacity: 0.1;
    }
}
  
/* heading  */
.loader_head h4{
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #FFFFFF;
} 
  
  
  /* @media only screen and (max-width: 968px) {
    .flexbox > div {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 33.3333333%;
      flex: 0 0 33.3333333%;
    }
  }
  
  @media only screen and (max-width: 768px) {
    .flexbox > div {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
    }
  }
  
  @media only screen and (max-width: 568px) {
    .flexbox > div {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
    }
  }
   */