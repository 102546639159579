
/* a{
  text-decoration: none !important;
  cursor: pointer !important;
}
.form-check-input:checked {
  background-color: #6341E0 !important;
  border-color: #6341E0 !important;
}
.MainBTN {
  padding: 10px 18px !important;
  text-transform: capitalize !important;
  height: 48px !important;
  background: #6341E0 !important;
  border: 1px solid #6341E0 !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #FFFFFF !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  transition: 0.3s !important;
}
.MainBTN:hover{
  background: #FFFFFF !important;
  border: 1px solid #D0D5DD !important;
  color: #6341E0 !important;
  transition: 0.3s !important;
}

.MainBTN2{
  padding: 10px 18px !important;
  text-transform: capitalize !important;
  height: 48px !important;
  background: #FFFFFF !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #21114D !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  gap: 6px !important;
  transition: 0.3s !important;
}
.MainBTN2:hover{
  background: #6341E0 !important;
  border: 1px solid #6341E0 !important;
  color: #FFFFFF !important;
  transition: 0.3s !important;
}

.MainBTNPink{
  padding: 10px 18px !important;
  height: 48px !important;
  background: #FFFFFF !important;
  border: 1px solid #D0D5DD !important;
  border-radius: 8px !important;
  text-decoration: none !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #6341E0 !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  cursor: pointer !important;
  gap: 6px !important;
  transition: 0.3s !important;
} 
.MainBTNPink:hover{
  background: #6341E0 !important;
  border: 1px solid #6341E0 !important;
  color: #FFFFFF !important;
  transition: 0.3s !important;
}



.form-select:focus {
  border-color: #D0D5DD !important;
  outline: 0 !important;
  box-shadow: none !important;
} */
 



/* ffffffffffff */

.gm-ui-hover-effect{
  display: none !important;
}
.gm-style-iw .gm-style-iw-c{
display: none !important;
}
.gm-style .gm-style-iw-d {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow:hidden !important
}
.gm-style-iw-ch{
  padding: 0px !important;
}

.gm-style-iw-d{
  overflow: hidden;
  max-height: max-content !important;
}

.gm-style .gm-style-iw-c {
  padding: 0px !important;
}
/* ffffff */




.main_Protected {
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  border: 1px solid;
  border-radius: 5px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  position: fixed;
  background-color: #fff;
  color: #000;
}
.Protected_heading h4 {
  background: #000;
  color: #fff;
  padding: 5px 34px 8px 10px;
  margin: 0;
}
.Protected_input .form-group {
  display: grid;
  grid-template-columns: 1fr;
  padding: 10px 15px;
  grid-gap: 10px;
  width: 80%;
}
.Protected_input .form-group  input{
  padding: 2px 5px;
}
.Protected_btn {
  text-align: end;
  padding: 0px 15px 15px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}