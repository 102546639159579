.Reazy_section {
    position: relative;
    width: 100%;
    float: left;
    display: block !important;
}

/* .Agent_bgg_data {
    display: grid;
    grid-template-columns: 500px auto;
    height: 100vh;
} */