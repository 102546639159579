@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100; 200; 300; 400; 500; 600; 700; 800; 900&family=Rajdhani:wght@300; 400; 500; 600&display=swap'); @import url('https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap'); * { font-family: 'Inter' !important; font-style: normal !important; }
h1 { font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 48px; line-height: 60px; letter-spacing: -0.02em; color: #21114D; margin: 0; }
h2 { font-weight: 600; font-size: 30px; line-height: 38px; color: #21114D; margin: 0; }
h4 { font-weight: 600; font-size: 20px; line-height: 30px; text-align: center; color: #21114D; margin: 24px 0; }
h5 { font-weight: 600; font-size: 18px; line-height: 28px; color: #21114D; margin: 0; font-family: 'Inter'; font-style: normal; }
h6 { font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 16px; line-height: 24px; color: #21114D; margin: 0; }
a { text-decoration: none !important; }
/* -------Input-label------- */
.align_left { text-align: left; }
label { transform: none !important; transition: none !important; position: relative !important; }
.input_field { width: 100%; }
.tablebody { /* max-height: 186px; */
  /* max-height: 290px; old */
  max-height: 336px; /* max-height: 400px; */
  overflow: auto; }
/* scroll css  */
.scroll::-webkit-scrollbar { width: 10px; }
/* Track */
.scroll::-webkit-scrollbar-track { box-shadow: inset 0 0 5px #fff; border-radius: 10px; }
/* Handle */
.scroll::-webkit-scrollbar-thumb { background: #6341E0; border-radius: 10px; }
/* Handle on hover */
.scroll::-webkit-scrollbar-thumb:hover { background: #6341E0; }
.form-label { font-weight: 500 !important; font-size: 14px !important; line-height: 20px !important; color: #21114D !important; width: 100%; float: left; margin-bottom: 6px !important; }
.form-control { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; padding: 9px 14px; width: 100%; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; box-sizing: border-box; background: #fff; }
.input_field input { background: #fff; border: 1px solid #E0DEF7; border-radius: 8px; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; padding: 10px 14px; }
.input_field .css-13cymwt-control { border: 1px solid #E0DEF7; border-radius: 8px; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; padding: 3px 0px 4px; min-height: 45px; }
.input_field .css-t3ipsp-control { min-height: 45px; border-radius: 8px; border: 1px solid #E0DEF7; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; padding: 3px 0px 4px; box-shadow: none; }
.input_field .css-t3ipsp-control:hover { border-color: #E0DEF7 !important; color: #21114D; box-shadow: none; }
.input_field .MuiSelect-select { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; padding: 10px 14px; }
.input_field .MuiSelect-select::after { content: ' .'; color: #fff; right: 10px; top: 12px; font-size: 0; background-repeat: no-repeat; position: absolute; pointer-events: none; padding: 0 7px; background-position: center; background-size: 15px; background-image: url("../../Images/icons/down_icon.png"); }
.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon,
.css-bpeome-MuiSvgIcon-root-MuiSelect-icon { display: none !important; }
.btn3.dropdown-toggle::after { display: none; }
body { overflow: inherit !important; padding-right: 0px !important; }
.updateinput { max-width: 80%; }
.updateForm { display: grid; grid-template-columns: 1fr 1fr !important; grid-gap: 20px !important; align-items: center !important; }
.don { display: flex; gap: 13px; flex-wrap: wrap; }
.Search_Filters .dropdown-item.active,
.Search_Filters .dropdown-item:active,
.Search_Filters a:hover { background-color: #6341E0 !important; color: #fff; }
/* .TenantFormInput .MuiSelect-select { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; padding: 10px 14px; }
*/
.Select_tabs { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; padding: 10px 14px; width: 100%; }
textarea { width: 100%; font-weight: 400; font-size: 16px; line-height: 24px; background: #FFFFFF; border: 1px solid #E0DEF7; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); border-radius: 8px; padding: 12px 14px; color: #21114D; resize: none; }
textarea:focus-visible { outline: none; }
.form-control:focus { box-shadow: none; border-color: #E0DEF7; }
.form-check-input { width: 20px; height: 20px; margin-right: 12px; border-color: #6341e0; cursor: pointer; float: left; }
.form-check-input:checked { background-color: #6341E0; border-color: #6341E0; }
.checkbox_gurp { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; padding: 15px; grid-template-columns: repeat(2, 1fr); display: grid; align-items: center; grid-gap: 15px; }
.checkbox_gurp .MuiFormGroup-root { display: flex; flex-flow: inherit; align-items: center; }
.checkbox_gurp label { margin-bottom: 0; }
.form-check { width: 100%; float: left; }
.form-check-label { width: auto; }
.form-check-label span { font-weight: 400; }
.form-check-input:focus { box-shadow: none; border-color: #6341e0; }
.dropdown-toggle { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; padding: 9px 14px; width: 100%; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; box-sizing: border-box; text-align: left; }
.dropdown-toggle::after { float: right; margin: 9px 0; }
.input_field.Mui-error:before,
.input_field.Mui-error:after input { border: 1px solid #FF5448 !important; }
.css-1636szt { display: none !important; }
fieldset { border-color: transparent !important; }
.input_field.Mui-error:before,
.input_field.Mui-error:after { background: transparent; border: 1px solid #FF5448 !important; border-radius: 8px; position: absolute; top: 0; border-radius: 4px; }
.input_field:before,
.input_field:after { border: none !important; }
.css-7obqij-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before { border-bottom: none !important; }
.css-1u9des2-indicatorSeparator { display: none; }
.AddLivingForms { margin-top: 15px; padding-bottom: 25px; }
.addlivingTabGurp { min-height: auto !important; }
.addlivingTabGurp .MuiTabs-flexContainer { text-align: center; display: block; }
.addlivingTabGurp .Mui-selected { color: #6341E0 !important; padding: 0; min-width: auto; }
.addlivingTabGurp button { padding: 0; color: #E6E4F8; min-width: auto; width: 10px; height: 10px; min-height: 10px; margin: 0 10px; border-radius: 6px; }
.addlivingTabGurp .css-1aquho2-MuiTabs-indicator { height: 0; display: none; }
/* -------Button------- */
.btn { background: #6341E0 !important; border: 1px solid #6341E0 !important; border-radius: 8px !important; box-shadow: 0px 1px 2px rgb(16 24 40 / 5%); padding: 9px 18px !important; text-transform: inherit !important; font-weight: 600 !important; font-size: 16px !important; line-height: 24px !important; color: #FFFFFF !important; height: fit-content !important; }
.btn:hover { color: #6341E0 !important; background: #F7F7FD !important; border: 1px solid #6341E0 !important; border-radius: 8px; }
.btn2 { background: #FFFFFF; border: 1px solid #6341E0 !important; box-shadow: 0px 1px 2px rgb(16 24 40 / 5%); font-weight: 600 !important; font-size: 16px !important; line-height: 24px !important; color: #6341E0 !important; padding: 9px !important; text-transform: inherit !important; border-radius: 8px !important; font-family: 'Inter' !important; }
.btn2:hover { color: #fff !important; background: #6341E0 !important; border: 1px solid #6341E0 !important; border-radius: 8px; }
.btn3 { padding: 9px 16px !important; background: #FFFFFF !important; border: 1px solid #E0DEF7 !important; box-shadow: 0px 1px 2px rgb(16 24 40 / 5%); border-radius: 8px !important; font-weight: 600 !important; font-size: 16px !important; line-height: 24px !important; color: #21114D !important; font-family: 'Inter' !important; text-transform: capitalize !important; }
.btn3>a { color: inherit; background: none; }
/* -------Nav-pills------- */
.nav-pills { display: flex; align-items: center; gap: 20px; margin: 50px 50px 0; position: relative; }
.nav-pills a { font-weight: 500; font-size: 14px; line-height: 20px; color: #21114D !important; text-decoration: none; padding: 4px 8px; }
.nav-pills a.active { font-weight: 600; font-size: 14px; line-height: 20px; color: #6341E0 !important; background: #E0DEF7 !important; border-radius: 6px; padding: 4px 8px; }
.nav-pills svg { padding: 4px 4px; }
.main-tabs { border-bottom: 1px solid #E0DEF7; margin-bottom: 0px; padding: 0 !important; }
.main-tabs button { font-weight: 600; font-size: 14px; line-height: 20px; color: #21114D; padding: 0 4px; text-transform: capitalize !important; }
.main-tabs .Mui-selected { padding: 0 4px; font-weight: 600; font-size: 14px; line-height: 20px; color: #6341E0 !important; }
.main-tabs .MuiTabs-indicator { background-color: #6341E0; }
.main-tabs .MuiTabs-flexContainer { gap: 6px; }
.progress-bar { position: relative; height: 10px; width: 100%; background: #E0DEF7; border-radius: 25px; }
.progress-fill { position: absolute; height: 10px; width: 100%; animation: progress-forward 3s infinite; border-radius: 15px; background: #6341E0; }
/* @keyframes progress-forward {
 0% { width: 0%; }
25% { width: 50%; }
50% { width: 75%; }
75% { width: 85%; }
100% { width: 100%; }
 }
; */
.ql-container.ql-snow { background: #FFFFFF; border: 1px solid #E0DEF7 !important; box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05); border-radius: 8px; padding: 12px 16px; }
.ReactQuill_setting { position: relative; }
.upgradeplan { font-weight: 600 !important; font-size: 14px !important; line-height: 20px !important; color: #6341E0 !important; }
.ReactQuill_setting .ql-toolbar.ql-snow { border: none; position: absolute; top: 16px; left: 50%; padding: 10px 20px; }
/* -----------MuiCheckbox------------ */
.MuiCheckbox-root { padding: 0; background: #FFFFFF; color: #E0DEF7; border-radius: 4px; }
.Export-img { margin-right: 5px; }
/* ------Form------ */
form { width: 500px; margin: 50px auto; position: relative; text-align: left; }
form h2 { margin-bottom: 32px; }
form .or p { font-weight: 600; font-size: 14px; line-height: 14px; color: #E0DEF7; margin: 0; }
form .or { text-align: center; position: relative; display: grid; align-items: center; margin: 20px 0 0; }
form .or:after,
form .or:before { content: ""; display: block; width: 45%; height: 2px; position: absolute; background: #E0DEF7; border-radius: 2px; }
form .or:after { right: 0px; }
form .or:before { left: 0px; }
form .Magic_Linkinput { position: relative; }
form .Magic_Linkinput img { position: absolute; right: 10px; top: 39px; }
form .form-text { font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; }
form .btn2 { width: 100%; margin-top: 24px; }
form .btn { width: 100%; padding: 11px; }
.Form_continue { margin: 24px 0; padding: 24px 0; border-top: 2px solid #E0DEF7; }
.click_upload { text-align: center; background: #fff; cursor: pointer; border-radius: 8px; padding: 20px; border: 1px solid #E0DEF7; position: relative; float: left; width: 100%; margin-bottom: 15px; }
.upload { width: 45px; height: 45px; border-radius: 50%; background: #EBEAFA; border: 1px solid #E0DEF7; margin: 0 auto; text-align: center; display: flex; justify-content: center; align-items: center; }
.upload img { width: 100%; max-width: 30px; }
.dragupload h6 { font-style: normal; font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; margin-bottom: 0; }
.dragupload h6 span { font-style: normal; font-weight: 600; font-size: 15px; line-height: 20px; color: #6341E0; }
.card_section { margin-top: 16px; }
.card_section .cancelImgs { position: relative; }
.card_section svg { position: absolute; top: 8px; right: 8px; z-index: 1; display: none; cursor: pointer; }
.cancelImgs img { width: 100%; height: 100%; position: relative; max-width: 350px; }
.card_section:hover.card_section svg { display: block; }
.crossbtn { width: 50px; height: 50px; position: absolute; right: 0; }
.passwordHide { position: relative; }
.passwordHide svg { position: absolute; right: 20px; top: 35px; }
.SettingpasswordHide { position: relative; }
.SettingpasswordHide svg { position: absolute; right: 20px; top: 10px; }
.card { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 12px; padding: 25px 12px; ; margin-top: 12px; }
.card_details { display: grid; grid-template-columns: auto 1fr; align-items: center; grid-gap: 12px; }
.adress_history_detail { display: grid; grid-template-columns: 1fr auto !important; }
.adress_history_detail .card_btn>button { min-width: auto !important; padding: 5px 10px !important; font-size: 14px !important; line-height: 18px !important; border-radius: 5px !important; }
.adress_history_detail .card_text span,
.adress_history_detail .card_text p { font-size: 12px !important; }
.card_section h2 { margin-bottom: 6px !important; }
.card_section p { margin-bottom: 4px !important; }
.card_section .card_text p { margin-bottom: 0 !important; font-weight: 500; font-size: 14px; line-height: 20px; color: #21114D; }
.card_section .card_text span { font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; }
.card_section .card_details img { width: 60px; box-sizing: border-box; border-radius: 4px; }
.profile_browsericon { width: 40px !important; height: 40px; border-radius: 100%; }
.card_icongurp svg { border: 1px solid rgba(33, 17, 77, 0.1); padding: 8px 8px; width: 40px; height: 40px; border-radius: 50%; }
.card_icongurp svg { border: 1px solid rgba(33, 17, 77, 0.1); padding: 8px 8px; width: 40px; height: 40px; border-radius: 50%; }
.featuresCard_Active { border: 1px solid #6341E0; }
.featuresCard_Active .card_text_more { display: flex; justify-content: space-between; }
.contract_card { display: grid; grid-template-columns: auto 1fr auto; align-items: center; padding: 10px; box-shadow: none !important; border: 1px solid #E0DEF7; border-radius: 8px !important; }
.contract_card img { width: auto; height: 50px; }
.contract_card .contract_cardnum { font-weight: 600; font-size: 18px; line-height: 28px; color: #6341E0; }
.contract_card .contract_cardnum span { font-weight: 500; font-size: 16px; line-height: 24px; color: #21114D; }
.contract_card .contract_textTop { font-weight: 500; font-size: 14px; line-height: 20px; color: #21114D; }
.contract_card .contract_textbottom { font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; }
.Sign_AsignForm { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; padding: 16px 16px 50px; }
.Sign_AsignForm form { margin: 0 !important; width: auto !important; }
.Sign_AsignForm .input_field input { border-bottom: 2px solid #E0DEF7 !important; border-radius: 2px; border: none; font-weight: 400; font-size: 45px; line-height: 45px; color: #21114D; text-align: center; }
/* --------------- */
.SelectFeatures_card { display: flex !important; flex-flow: wrap; gap: 9px !important; border: 1px solid #E0DEF7 !important; padding: 16px !important; margin-top: 12px !important; }
.SelectFeatures_card button { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 6px; padding: 7.5px 10px; font-weight: 500; font-size: 14px; line-height: 20px; text-align: center; color: #21114D; }
.SelectFeatures_card .addedBtn { background: #E0DEF7; border: 1px solid #6341E0; color: #6341E0; }
.SelectFeatures_card .addFeatures { font-weight: 600; color: #6341E0; border: none; }
form .purpleB-text { color: #6341E0; font-weight: 700; }
.link-btn { color: #6341E0; border: none !important; background: none !important; outline: 0 !important; box-shadow: none !important; padding: 0 !important; font-weight: 700; }
/* --------------------------- */
table { width: 100%; }
.Properties_table { min-width: 1200px; }
.DetailsView_table { min-width: 1200px; }
.watchlist_table { min-width: 1200px; }
.SettingTeam { min-width: 991px; }
.table p { margin-bottom: 0; }
.table a { font-weight: 600; font-size: 14px; line-height: 20px; color: #6341E0; text-decoration: none; }
.action_td .Delete { font-weight: 600; font-size: 14px; line-height: 20px; color: #21114D; }
.Properties_table tr { display: grid; align-items: center; grid-template-columns: auto 2fr repeat(5, 1fr) 100px; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.SettingTeam tr { display: grid; align-items: center; grid-template-columns: auto 3fr 1fr 120px; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.DetailsView_table tr { display: grid; align-items: center; grid-template-columns: auto 1fr 1fr 2fr 1fr 1fr 150px; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.tenantviewList_table tr { grid-template-columns: auto 350px 1fr 2fr 1fr 1fr 150px; }
.applicant-grid-main { gap: 10px; flex-wrap: wrap; }
.applicant-grid { display:grid; grid-template-columns: 1fr auto; align-items: center; }
.applicant-grid .circlebg-greytxt { width: 40px !important; height: 40px !important; display: flex; justify-content: center; align-items: center; }
.applicant-grid .circlebg-purple { width: 40px !important; height: 40px !important; }
.applicant-grid .circlebg-purple>img { width: 50% !important; height: 50% !important; }
.modallisting_table tr { display: grid; align-items: center; grid-template-columns: auto 2fr 1fr; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.PersonalInformation_modal tr { display: grid; align-items: center; grid-template-columns: 1fr 1fr 2fr; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.Perapproval_modal tr { display: grid; align-items: center; grid-template-columns: 2fr 1fr; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.listing_table tr { display: grid; align-items: center; grid-template-columns: auto 2fr 1fr 1fr 1fr 180px; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.Application_table tr { display: grid; align-items: center; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; grid-template-columns: auto 2fr 1fr 1.5fr 1fr; }
.listingmodal_table tr { display: grid; align-items: center; grid-template-columns: 2fr 1fr 1fr 140px; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.listingTenantmodal_table tr { display: grid; align-items: center; grid-template-columns: 1.5fr 1fr 1fr 1.5fr; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.modal_view .Search_Filters { display: grid; grid-template-columns: 1fr auto; margin-bottom: 32px; padding: 0 32px; }
.modal_view .Search_Filters .input-group { width: auto; }
.watchlist_table.table thead { border-radius: 12px; }
.watchlist_table.table tbody tr:last-child { border: none; }
.watchlist_table .action_td { text-align: left !important; }
.watchlist_table .action_td button { padding: 0; min-width: auto !important; }
.SelectProperty_table thead { border-radius: 10px; }
.SelectProperty_table table { width: 100%; }
.SelectProperty_table tr { display: grid; align-items: center; grid-template-columns: 8% 65% 24%; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.SettingTeam .action_td { display: grid !important; grid-template-columns: auto 1fr; width: 120px !important; }
.ReviewDocuments_table tr { display: grid; align-items: center; grid-template-columns: 2fr 1fr; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.ReviewDocuments_table { background: #FFFFFF; border: 1px solid #E7E6F9; border-top: 0; border-bottom: 0; margin-top: 16px; }
.SettingTeam .action_td a:hover { background-color: transparent; }
.table th { border: none; }
.tr-pad tr { padding: 0; }
.review-docs h2,
.review-docs p { color: #21114d; }
.tr-pad { border: 1px solid #e7e5f9; }
.tr-pad thead { background: #f7f7fd; }
.tr-pad a,
.tr-pad a svg { color: #6341E0; }
.tr-pad td,
.tr-pad th { border-right: 1px solid #e7e5f9; }
.tr-pad tr td,
.tr-pad tr th { padding: 13px 16px; gap: 10px; }
.proprtySlack { display: flex; align-items: center; gap: 12px; }
.SelectProperty_table tr th:last-child { position: relative; }
.table { border: none !important; margin-bottom: 0 !important; }
.modallisting_table { padding-bottom: 100px; height: calc(100vh - 452px); overflow-y: scroll; }
.table-pf-border tr { display: grid; align-items: normal; grid-template-columns: 172px 140px 2fr; border-bottom: 1px solid #E0DEF7; /* padding: 13px 16px; */
  padding: 0; gap: 0; word-wrap: break-word; font-size: 12px; }
.table-pf-border tr td:first-child { word-break: break-all; word-wrap: break-word; }
.Perapproval_modal tr { display: grid; align-items: normal; grid-template-columns: 70% 1fr; border-bottom: 1px solid #E0DEF7; /* padding: 13px 16px; */
  padding: 0; gap: 0; }
.Perapproval_modal tr td,
.Perapproval_modal tr th { width: 100%; }
.Perapproval_modal tr th { font-size: 12px; }
.m-thead h4 { font-family: Inter; font-size: 20px; font-weight: 600; line-height: 28px; letter-spacing: 0em; color: #21114D; }
.m-thead span .MuiSvgIcon-root { color: #6341E0 !important; }
.table-pf-border tr th { font-size: 12px; font-weight: 500; line-height: 18px; letter-spacing: 0em; color: #21114D; }
.table-pf-border tr td,
.table-pf-border tr th { border: 1px solid #E0DEF7 !important; padding: 12px 12px 12px 12px; }
.SelectProperty_table tr th:last-child::after { position: absolute; content: " "; left: -7px; width: 2px; top: -13px; height: 51px; background-color: #E0DEF7; }
.Perapproval_modal th:first-child { padding: 16px 16px 16px 32px; }
.Perapproval_modal th:last-child { padding: 16px 32px 16px 16px; width: 100%; text-align: end; }
.doc-detail { width: 100%; padding: 16px 32px 16px 16px; border-right: 1px solid #E0DEF7; gap: 12px }
.doc-detail p { font-size: 10px; line-height: 20px; }
.toggle-head { display: flex; }
.icon-t-s { padding: 16px 32px 21px 16px; width: 100%; text-align: end; }
.toggle-details { width: 80%; font-size: 10px; line-height: 20px; letter-spacing: 0em; margin: 0 auto; }
.toggle-details span { font-weight: 700; }
.toggle-head h6 { font-family: Inter; font-size: 14px; font-weight: 400; line-height: 20px; letter-spacing: 0em; color: #21114D; }
.toggle-head .icon-btn { background: none; border: none; box-shadow: none; padding: 0; margin-left: auto; font-weight: 600 !important; font-size: 16px !important; line-height: 24px !important; }
.status_orange { background: #FF7A000D; color: #FF7A00 !important; }
.status_purple_lt { background: #F7F7FD; color: #6341E0 !important; }
.status_green_lt { background: #41D6E00D; color: #41D6E0 !important; }
.text-lt-orange { color: #FF7A00 !important; }
.text-lt-purple { color: #6341E0 !important; }
.text-green_lt { color: #41D6E0 !important; }
.img-wrap { width: 76px; overflow: hidden; word-wrap: break-word; }
.Listings { padding: 50px; text-align: left; }
.m-l-head { display: flex; flex-flow: column; gap: 12px; }
.card_text_more_w { display: grid; grid-template-rows: 1fr; width: 80%; }
.subt-h { color: #21114D; font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: 0em; }
.subt-h span { color: #6341E0; font-weight: 600; }
.tanantlist_table { min-width: 1200px; }
.tanantlist_table tr { display: grid; align-items: center; /* grid-template-columns: auto 2fr 1fr auto 2fr repeat(3 , 1fr) auto; */
  grid-template-columns: auto 250px 100px 80px 120px repeat(3, 1fr) 95px; font-size: 14px; border-bottom: 1px solid #E0DEF7; padding: 13px 16px; gap: 10px; }
.tanantlist_table1 tr { grid-template-columns: 35px 250px 150px 80px 192px repeat(3, 150px) 95px; }
.circle-icon-b { width: 32px; height: 32px; border-radius: 50%; border: 1px solid #e7e5f9; text-align: center; vertical-align: middle; line-height: 28px; background: #dedbf5; color: #21114D; font-weight: bold; }
.tanantlist_table thead tr { text-align: left; font-size: 14px; font-weight: 500; color: #21114D; }
.action_col { width: 100%; }
.btn-trans { background: none !important; padding: 0 !important; border: none !important; }
.table thead { width: 100%; float: left; border-top: 1px solid #E0DEF7; background: #F7F7FD; }
.table tbody { width: 100%; float: left; }
.table td { border: none; align-items: center; display: flex; gap: 10px; font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; }
.table .Created_img { width: 32px; height: 32px; border-radius: 100%; }
.table .listed { font-weight: 500; font-size: 12px; line-height: 18px; color: #21AA48; display: grid; grid-template-columns: auto 1fr; grid-gap: 5px; }
.table .listed span { font-size: 40px; margin-top: -12px; }
.table_section { border: 1px solid #E0DEF7; border-radius: 12px; margin-top: 24px; float: left; background: #fff; width: 100%; }
.table .card_info { margin: 0; display: flex; align-items: center; gap: 6px; }
.table_card .card { border: none; display: grid; grid-template-columns: auto 1fr; grid-gap: 12px; align-items: center; padding: 0; margin-top: 0; }
.table_card .card-header { border: none; background: transparent; padding: 0; }
.table_card .card-header img { width: 76px; height: 50px; border-radius: 4px; }
.table_card .card-title { font-weight: 500; font-size: 14px; line-height: 20px; color: #21114D; margin: 0; }
.table_card .card-body { padding: 0; }
.table_card .card-text { font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; margin: 0 !important; }
.table .action_td { width: 70px; text-align: center; display: block; }
.table_card .card-header img { width: 76px; height: auto; min-height: 76px; max-height: 76px; }
.TdImags { width: 32px; height: auto; margin-right: 10px; }
.updatepriceBond { align-items: unset !important; }
.date_lease input { background: #fff; border: 1px solid #E0DEF7; border-radius: 8px; font-weight: 400; font-size: 16px; line-height: 24px; color: #21114D; padding: 10px 14px; position: relative; }
.date_lease input:focus-visible { outline: -webkit-focus-ring-color auto 0px; }
.date_lease input::after { position: absolute; content: " +++ "; top: 0; right: 25px; width: 100%; height: 100%; background: #000; }
.date_lease select { background: #fff; border: 1px solid #E0DEF7; border-radius: 8px; padding: 10px 14px; background: aliceblue; }
.date_lease select:focus-visible { outline: -webkit-focus-ring-color auto 0px; }
.date_lease select>option { height: 500px; padding: 50px 0px !important; }
.table_tab .action_td button { border: none; font-weight: 600; font-size: 14px; line-height: 20px; color: #6341E0; text-align: left; }
.DetailsView_table .action_td { width: 150px; text-align: left; }
.DetailsView_table .update_action_td { width: 100% !important; }
.DetailsView_table .action_td button { padding: 0; }
.listing_table .action_td { width: 180px; }
.table_footer tr { display: grid; grid-template-columns: 1fr auto; width: 100%; align-items: center; grid-gap: 16px; padding: 8px 15px 15px; }
.btn_footer { gap: 10px; }
.Application_table .action_td { width: auto; text-align: right; }
.Application_table .action_td button { padding: 0; }
.listingmodal_table .action_td { width: auto; }
.listingmodal_table .action_td button { border: none; font-weight: 600; font-size: 14px; line-height: 20px; color: #6341E0; padding: 0; min-width: auto; margin-right: 25px; }
/* ------------------------- */
.listingmodal_details { overflow-y: auto; }
.listingmodal_details .nav-pills { margin: 32px 32px 0; }
.listingmodal_details .btn_gurpModal { grid-template-columns: 1fr; grid-gap: 0; margin: 0 32px; display: grid; }
.listingmodal_details .main-tabs { border-bottom: 1px solid #E0DEF7; padding: 0 !important; margin: 0px 32px 24px; }
.listingmodal_details .Search_Filters { margin: 0 32px; }
.listingmodal_details .Search_Filters .input-group { width: 100%; }
.modal_tabhead { display: flex; align-items: center; padding: 0 32px; gap: 10px; margin-bottom: 16px; }
.modal_tabhead h4 { margin: 0; }
/* .listingmodal_details .btn_gurpModal { background: #fff; position: fixed; bottom: 32px; }
*/
.listingmodal_details .btn_gurpModal button { /* position: absolute; */
  position: fixed; width: 496px; bottom: 32px; }
.modal_area_top { padding: 32px 32px 10px 32px; }
.mb-100 { margin-bottom: 100px !important; }
.modal_view { padding: 0 !important; }
.modal_view .nav-pills { margin: 32px 32px 0 !important; }
.modal_view .Review_card .card-body { padding: 16px 32px !important; }
.modal_view .main-tabs { padding: 0 32px !important; }
.padding_modal { padding: 0 32px; }
.modal_view .btn_gurpModal { display: grid; grid-template-columns: 2fr auto auto; grid-gap: 20px; margin: 30px 0; position: absolute; bottom: 0; width: 100% !important; padding: 0 32px; }
.AddInspection_btn { width: 100%; padding: 0 32px; }
.AddInspection_btn button { width: 100%; }
.modal_scroll { height: calc(100vh - 104px); top: 0; overflow-x: auto; }
.PropertiesTenant .table_tab .MuiTabs-flexContainer { border: none !important; margin: 0; }
.PropertiesTenant .table_tab .MuiTabs-flexContainer button { border-right: 0; border-bottom: 2px solid #6341E0; border-radius: 0; }
.view_watchlist { font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 18px; line-height: 28px; color: #6341E0; }
/* ---------SelectProperty-------------- */
.SelectProperty .Form_continue { float: left; width: 100%; border-top: 0; margin: 0; }
.SelectProperty .footer { background: transparent; border-top: none; }
.SelectProperty .footer button { font-weight: 600; font-size: 14px; line-height: 20px; color: #6341E0; background: transparent; border: none; box-shadow: none; margin: 25px 0; padding: 0; }
.SelectProperty .footer button svg { font-size: 20px; margin-right: 10px; }
.representing_sec { display: grid; }
.dashboardFooterRental { position: fixed; bottom: 0; width: -webkit-fill-available; }
/* ---------Confirm_Listing-------------- */
.Confirm_Listing h4 { margin: 0; text-align: left; }
.Confirm_Listing .card_text p { font-weight: 600; font-size: 14px; line-height: 20px; color: #21114D; }
.Confirm_Listing .card_text span { font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; }
.Confirm_Listing .card_btn { text-align: end; }
.Confirm_Listing .card_btn button { font-weight: 600; font-size: 14px; line-height: 20px; padding: 8px 14px; }
.DropSelect { background: url(../../Images/drops.png); background-position: center; background-repeat: no-repeat; width: 20px; height: 12px; background-size: 100% 100%; position: relative; top: 35px; }
#demo-simple-select { position: absolute; width: auto; right: 0; font-size: medium; }
.Price_bond { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; border-bottom: none; }
/* Share Modal  */
.close-button { position: absolute; z-index: 99; top: 20px; right: 20px; background: none; color: #fff; border: none; }
.ShareModal { height: 100vh; background: #0000005e; display: flex; justify-content: center; align-items: center; }
.socialicons { background: #6751b9e0 !important; border-radius: 5px; max-width: max-content; height: auto; display: flex; flex-flow: column; align-items: baseline; padding: 60px 60px; gap: 5px; position: relative; }
.socialicons .makeStyles-container-9 { background: transparent !important; padding: 0px !important; }
.socialicons .makeStyles-container-9 .makeStyles-copyContainer-13 { display: none; }
.coppyicon { /* position: absolute; */
  z-index: 999; top: 115px; border-radius: 50px; right: 303px; background: blue; }
[class*="copyContainer"],
.sub-div-social>div>div:nth-child(2) { height: auto; position: absolute !important; left: 0; width: 100% !important; min-width: 300px; padding: 16px !important; margin: auto; margin-bottom: 10px; }
/* [class~="makeStyles-copyIcon-"] */
[class*="copyIcon"],
.sub-div-social>div>div:nth-child(2)>div:nth-child(2) { color: #0C66FF; right: 0px; width: auto; position: absolute; top: 0; font-weight: bold; }
[class*="copyIcon"] p,
.sub-div-social>div>div:nth-child(2)>div:nth-child(2) p
/* [class~="makeStyles-copyIcon-"] p */ { padding: 4px 6px 6px 6px; margin: 0; }
.sub-div-social { display: flex; align-items: baseline; background-color: #fff; padding-right: 5px; gap: 5px; min-height: fit-content; max-height: -webkit-fill-available; }
.socialicons>div>div { padding-right: 0; padding-left: 0; }
.copy-status { text-align: center; display: block; width: 100%; color: #fff; }
.coppyicon svg { padding: 5px; color: #fff; border: 1px solid #ffffff94; font-size: 40px; border-radius: 5px; }
/* .makeStyles-copyIcon-23 { }
*/
/* Agent Model  */
.agentModel { height: 100vh; background: #0000005e; display: flex; justify-content: center; align-items: center; }
.AgentDiv { background: #fff !important; border-radius: 5px; max-width: 550px; height: auto; width: 100%; display: flex; flex-direction: column; padding: 35px; gap: 30px; }
.verifi_list { max-height: 40vh; overflow: auto; }
.topAgentmod { display: flex; align-items: center; gap: 35px; }
.agentname p { margin: 0; color: #000; font-size: 20px; font-weight: 500; line-height: 100%; }
.agentContact,
.agentname { display: flex; flex-direction: column; gap: 10px; }
.agentContact p { margin: 0; font-size: 18px; font-weight: 500; }
.agntBtns { display: grid; grid-template-columns: 30% auto; width: 100%; gap: 50px; }
/* --------ReviewProperty----------------- */
.inputtext_u { height: 182px; width: 100%; padding: 16px 16px 16px 16px; border-radius: 8px; border: 1px; font-family: 'La Belle Aurore', cursive !important; display: flex; align-items: center; text-align: center; margin: auto; }
.inputtext_u h2 { font-family: 'La Belle Aurore', cursive !important; font-size: 45px; text-align: center; border-bottom: 2px solid #E0DEF7; width: 100%; font-weight: 400; line-height: 45px; letter-spacing: 0em; text-align: center; color: #21114D; }
.ReviewProperty { background: #f7f7fd; float: left; padding-bottom: 100px; position: relative; width: calc(100% - 280px); }
.ReviewProperty form { width: 500px; margin: 50px auto; position: relative; text-align: left; }
.css-185gdzj-MuiCardHeader-root { padding: 8px !important; }
.ReviewProperty form h2 { margin-bottom: 12px; }
.ReviewProperty form p { margin-bottom: 32px; margin-top: 0; text-align: left; }
.ReviewProperty .card_sec p { font-weight: 400; font-size: 14px; line-height: 22px; color: #21114D; }
.ReviewProperty .card p { margin-bottom: 0; }
.ReviewProperty .card { background: #FFFFFF; border: 1px solid #E0DEF7; border-radius: 12px; padding: 16px; display: grid; grid-template-columns: 1fr; grid-gap: 0px; align-items: center; margin-bottom: 12px; }
.Review_card .card-title { font-weight: 500; font-size: 14px; line-height: 20px; color: #6341E0; }
.Review_card .card-header { padding: 0; background: transparent; border: none; }
.Review_card .card-header img { width: 100%; max-height: 340px; object-fit: contain; }
.Review_card .card-body { padding: 20px 0; }
.Review_card .slide_images { display: grid; grid-template-columns: repeat(4, 1fr); grid-gap: 20px; margin: 20px 0 0; }
.Review_card .card-text { font-weight: 600; font-size: 18px; line-height: 28px; color: #21114D; display: grid; grid-template-columns: 1fr auto; margin: 4px 0 !important; }
.Review_card .card_details { font-weight: 500; font-size: 14px; line-height: 20px; color: #21114D; display: grid; grid-template-columns: 1fr auto; }
.Review_card .card_info { display: flex; grid-gap: 6px; align-items: center; font-weight: 500; font-size: 16px; line-height: 24px; color: #21114D; margin: 16px 0 0; }
.Review_card .card_info:nth-child(odd) { grid-gap: 20px; }
.Review_cardFeatures { text-align: left; margin: 20px 0 12px; }
/* .slide_images img { width: 100%; }
*/
.slide_images img { width: 102px; height: 68px; }
/*View Property Button list*/
/* -------------ReviewDocuments------------------- */
.ReviewDocuments form h2 { margin-bottom: 12px; }
/* --------PropertiesTenant------------- */
.PropertiesTenant form { width: 100%; padding: 0 50px; margin: 40px 0 0; }
.Description p { font-family: 'Inter'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 22px; color: #21114D; }
/* .PropertiesTenant .nav-pills button { position: absolute; right: 0; }
*/
.PropertiesTenant .table_tab .MuiTabs-root { border-bottom: 1px solid #E0DEF7; padding: 25px 50px 0 !important; margin-bottom: 0 !important; }
.PropertiesTenant_card { display: grid; grid-template-columns: auto 1fr auto; align-items: center; gap: 20px; border-bottom: 2px solid #E0DEF7; padding-bottom: 32px; }
.AgentCard { display: flex; align-items: center; gap: 10px; }
.AgentCard-custom img { width: 60px; height: 60px; border-radius: 50%; }
.AgentCard span { font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 12.5px; line-height: 16px; color: rgba(33, 17, 77, 0.5); }
.PropertiesTenant .card { padding: 32px; }
.PropertiesTenant .Review_card .slide_images { display: flex; margin: 20px 0; flex-wrap: wrap; }
.PropertiesTenant .Review_card .slide_images img { width: 110px; height: 70px !important; }
.PropertiesTenant .Review_card .card-header img { height: 500px; max-height: 500px; }
.card-header .relativee_FeaturedImg img { width: 100%; max-height: 550px !important; height: auto !important; object-fit: cover !important; }
.relativee_FeaturedImg img { width: 100%; max-height: 250px !important; height: 180px !important; object-fit: cover !important; }
.absolute_FeaturedImg h5 { color: #fff; font-weight: 700; text-shadow: 1px 1px #000; }
.absolute_FeaturedImg h5 img { width: 25px !important; margin-top: -3px; }
/* ----------------------- */
.browser_property { display: grid; grid-template-columns: 1fr 1fr 1fr; grid-gap: 25px; padding: 32px; margin-top: 70px; }
.slider-inner .owl-carousel .owl-stage-outer { padding: 4px 0 !important; }
.browser_property img { width: 100%; }
.Tenant_browerImg { height: 250px; }
.tenant_AppCard .card-text p { font-family: 'Inter'; font-style: normal; font-weight: 700; font-size: 20px; line-height: 20px; color: #6341E0; }
.tenant_AppCard .card-text span { font-family: 'Inter'; font-style: normal; font-weight: 600; font-size: 12.5px; line-height: 12px; color: rgba(33, 17, 77, 0.5); }
.tenant_AppCard .card_details p { font-family: 'Inter'; font-style: normal; font-weight: 500; font-size: 14px; line-height: 20px; color: #21114D; margin-bottom: 0; }
.tenant_AppCard .card_details span { font-family: 'Inter'; font-style: normal; font-weight: 400; font-size: 14px; line-height: 20px; color: #21114D; }
.tenant_AppCard .card_details { display: grid; grid-template-columns: 1fr; align-items: center; grid-gap: 6px; margin-bottom: 16px; }
.tenant_AppCard .card_info { display: flex; grid-gap: 6px; align-items: center; font-weight: 500; font-size: 16px; line-height: 24px; color: #21114D; margin: 16px 0 0; }
.tenant_AppCard .card_info p { margin: 0; }
.tenant_AppCard .home { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 5px; }
.top_bar { padding: 12.5px 32px; display: flex; gap: 10px; width: inherit; background: #FFFFFF; border-bottom: 1px solid rgba(33, 17, 77, 0.1); position: fixed; z-index: 1; }
.top_bar .Search_Filters { display: grid; grid-template-columns: 1fr auto; }
.top_bar .Search_Filters button { width: fit-content; }
.no-properties-found { display: flex; align-items: center; justify-content: center; height: 100%; /* Make sure the parent container has a height set (e.g., min-height: 100vh; ) */
  text-align: center; font-size: 24px; color: black; }
/* adding style switch-view button */
.switch-view .form-select,
.map-list-nearby .form-select { background-color: #6341E01A; background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236341E0' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e"); color: #6341E0 !important; font-weight: 600 !important; font-size: 16px !important; }
.switch-view .form-select:focus { box-shadow: none; }
.PropertListMap { width: 234px; /* height: 289px; */
  position: absolute; z-index: 2; top: 57px; left: 161px; padding: 0 !important; }
.PropertListMap .card-body { padding: 25px; border-radius: 0px 5px 5px 0px; border: 1px 1px 1px 0px; gap: 25px; }
.PropertListMap .card-text p { color: #6341e0; font-family: Inter; font-size: 20px; font-style: normal; font-weight: 700; line-height: 20px; }
.PropertListMap .card-text span { color: rgba(33, 17, 77, .5); font-family: Inter; font-size: 12.5px; font-style: normal; font-weight: 600; line-height: 12px; }
.card-block { display: grid; grid-template-columns: 1fr; gap: 15px; }
.PropertListMap .card_details { display: grid; grid-template-columns: 1fr; font-family: Inter; font-size: 14px; font-weight: 500; line-height: 20px; letter-spacing: 0em; gap: 0; }
.PropertListMap .icon-group-menu { display: flex; width: auto; margin-left: auto; background: none !important; border: none; gap: 10px; padding: 0; }
.icons-main { display: inline-flex; gap: 10px; }
.PropertListMap .card_info { display: flex; gap: 24px; font-size: 14px; font-weight: 400; line-height: 20px; letter-spacing: 0em; text-align: left; }
.PropertListMap .card_info .home { display: flex; gap: 10px; }
.btn-blue { background: #21114D; color: #fff; padding: 7.5px; border-radius: 5px; gap: 10px; box-shadow: 5px 5px 10px 0px #21114D40; }
.reltv_feturImg { position: relative; }
.reltv_feturImg img { width: 100%; max-height: 200px !important; height: 150px !important; object-fit: cover !important; }
.ab_featureimg p { background: #FFFFFF; mix-blend-mode: normal; border: 1px solid #E0DEF7; border-radius: 5px; font-weight: 500; font-size: 14px; line-height: 20px; text-align: center; color: #6341E0; padding: 5px 7.5px; margin-bottom: 0; }
.ab_featureimg { position: absolute; bottom: 30px; left: 30px; }
.PropertListMap .icon-group-menu .icons-m { width: 40px; height: 40px; border: 1px solid #21114D1A; border-radius: 20px; }
.PropertListMap .card_details span { font-weight: 400; display: block; }
/*map-view*/
.iframe-v { border: 0; position: relative; background: #ddd; border: none; top: 0; left: 0; width: 100%; height: 100%; }
.map-view { width: 100%; display: block; /* height: calc(100vh - 450px); */
  /* height: 650px; */
  margin-top: 70px; position: relative; }
.map-wrap { display: grid; grid-template-rows: 1.8fr 1fr; grid-gap: 5px; padding: 15px; overflow: hidden; }
.Slider-box-wrap { max-width: 100%; width: 100%; overflow: hidden; }
.slick-slider { margin: 30px auto 50px; position: relative !important; }
.slick-prev,
.slick-next { top: calc(100% + 10px) !important; z-index: 1; }
.slick-prev { left: 10px !important; bottom: 0; }
.slick-next { right: 10px !important; }
.slick-prev:before,
.slick-next:before { color: #6341E0 !important; }
.slider-inner { width: 100%; }
.slick-track { align-items: stretch; display: flex !important; }
.slick-slide { align-items: stretch; display: grid; height: auto }
.slick-initialized .slick-slide { display: grid !important; height: inherit !important; min-height: fit-content; float: none !important; align-items: stretch !important; padding-bottom: 6px; }
.slick-slide>div { min-height: 100%; }
.slick-slide>div>div { min-height: inherit; display: grid !important; }
.slick-slide>div>div>div { min-height: inherit; }
.card-small-o-w { height: 100% !important; min-height: inherit; display: flex; flex-flow: column; }
.tenant_AppCardSmall { display: flex; flex-flow: column; flex-wrap: wrap; height: 100%; }
.tenant_AppCardSmall p { line-height: normal !important; }
/* .map-wrap { width: 100%; display: flex; max-width: 100%; overflow: hidden; }
*/
.iframe-100 { width: 100%; display: block; height: 70vh; margin-top: 70px; position: relative; }
.iframe-wrap { position: relative; width: 100%; padding: 10px; margin: 100px auto 50px auto; }
.map-list-nearby { position: fixed; top: 80px; right: 290px; display: flex; gap: 10px; z-index: 1; }
.map-list-nearby .form-select,
.map-list-nearby .btn-white { background-color: #fff !important; border-radius: 0; padding-top: 5px; padding-bottom: 5px; }
.map-list-nearby .btn-white { background-color: #fff !important; color: #6341E0 !important; border-radius: 0 !important; padding: 5px 18px !important; border: none !important; box-shadow: none; }
@media screen and (max-width:1024px) {
 .btn { background: #6341E0 !important; border: 1px solid #6341E0 !important; border-radius: 8px !important; box-shadow: 0px 1px 2px rgb(16 24 40 / 5%); padding: 9px 12px !important; text-transform: inherit !important; font-weight: 600 !important; font-size: 14px !important; line-height: 24px !important; color: #FFFFFF !important; height: -moz-fit-content !important; height: fit-content !important; }
 }
.main_tenantApp { display: grid; grid-template-columns: 1fr 280px; }
.Right_sidebar { background: #FFFFFF; position: relative; width: 100%; min-height: 100vh; height: auto; }
.Right_sidebar .test { background: #fff; margin: 70px 0 0 !important; position: fixed !important; width: 280px !important; float: left; padding: 25px; top: 0; bottom: 0; }
.Right_sidebar .btn_gurp button { font-size: 12.5px !important; line-height: 12px !important; padding: 10px 20px !important; }
.Right_sidebar .btn_gurp { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 15px; }
.Right_sidebar .btn_gurp.reset_apply { position: absolute; width: calc(100% - 50px); bottom: 25px; }
.Right_sidebar p { font-weight: 600; font-size: 12.5px; line-height: 12px; text-align: left; color: #21114D; margin-bottom: 20px; margin-top: 20px; }
/* .Right_sidebar label { font-weight: 600; font-size: 12.5px; line-height: 12px; text-align: left; color: #21114D; margin-bottom: 20px; margin-top: 20px; }
*/
.Right_sidebar .btn_gurp2 { display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 15px; }
.Right_sidebar .btn_gurp2 button { min-width: auto; padding: 10px !important; font-size: 12.5px !important; line-height: 12px !important; }
.Advanced_Filters { width: 100%; margin-bottom: 15px !important; font-weight: 600 !important; font-size: 12.5px !important; line-height: 12px !important; padding: 10px 0 !important; }
.relativee_FeaturedImg { position: relative; }
.absolute_FeaturedImg p { background: #FFFFFF; mix-blend-mode: normal; border: 1px solid #E0DEF7; border-radius: 16px; font-weight: 500; font-size: 14px; line-height: 20px; text-align: center; color: #6341E0; padding: 4px 8px; margin-bottom: 0; }
.absolute_FeaturedImg { position: absolute; bottom: 30px; left: 14px; }
.absolute_button { position: absolute; top: 14px; left: 14px; }
.absolute_button .btn { background: #21114D !important; border: none !important; border-radius: 8px !important; font-weight: 600 !important; font-size: 16px !important; line-height: 20px !important; padding: 8px 8px !important; }
.imgOuter { position: absolute; bottom: -25px; left: 0; width: max-content; right: 0; display: flex; align-items: center; box-shadow: 0px 0px 15px #000000ad; margin: 0 auto; border-radius: 50px }
.imgOuter h3 { background: #fff; color: #000; padding: 15px 25px; margin: 0; border-right: 2px solid #0000007a; }
.imgOuter h3:first-child { border-radius: 50px 0px 0px 50px; }
.imgOuter h3:last-child { border-radius: 0px 50px 50px 0px; border-right: none; }
/* approbl amount  */
.apprvAmot { position: relative; top: 55px; }
.apprvAmot h3 { color: #21114D; text-transform: capitalize; margin: 0; font-family: Inter; font-size: 30px; font-style: normal; font-weight: 600; line-height: 38px; }
.apprvAmot p { color: #21114D; font-family: Inter; font-size: 16px; font-style: normal; font-weight: 500; line-height: 24px; }
/* ---------- */
.Property { position: relative; background: #F7F7FD; float: left; width: calc(100% - 280px); padding-bottom: 100px; padding-left: 20px; }
.checkicon { background: #6341E0; border: 1px solid #6341E0; border-radius: 100%; color: #fff; padding: 2px; }
.icon_margin { margin-right: 5px; }
/* -----footer------- */
.footer { border-top: 1px solid #E0DEF7; background: #fff; position: fixed; width: calc(100% - 280px); align-items: center; bottom: 0; display: grid; grid-template-columns: auto 1fr auto; padding: 0 25px; }
.footer_section { display: grid; grid-template-columns: repeat(4, auto); width: 290px; align-items: center; margin: 0 auto; padding: 25px 0; grid-gap: 54px; }
.footer_section span { width: max-content; position: relative; }
.footer svg { font-size: 32px; }
.Active_Dark_circle svg { color: #6341E0; }
.footer_section span:before { content: ""; display: block; height: 2px; background: #6341E0; border-radius: 2px; position: absolute; width: 49px; left: 34px; top: 15px; }
.footer_section span:last-child:before { display: none; }
.footer .light_circle svg { color: #E0DEF7; }
.footer .light_circle:before { background: #E0DEF7; }
.footer .btn3 { text-transform: inherit; }
.togle-btn { display: none !important; padding: 5px 0px !important; min-width: auto !important; }
/*property Card small */
.fs-sm-auto { font-size: 12px; color: #6341e0; padding: 5px; display: inline-block; background-color: #fff; border-radius: 5px; }
.absolute_card_header { bottom: 0px !important; width: 100%; left: 0px; }
.small-grup { padding: 0 !important; margin: auto; }
.small-grup svg { border: 1px solid rgba(33, 17, 77, 0.1); padding: 5px 5px; width: 30px; height: 30px; border-radius: 50%; background-color: #fff; margin-left: 3px !important; }
.card-small-header { display: block !important; }
.cardSmall_FeaturedImg img { width: 100%; max-height: 200px !important; height: 130px !important; object-fit: cover !important; }
.small-card-deatils { flex: 1 1 auto; }
.smallcard_info { font-size: 12px; margin-top: 0px !important; }
.smallcard_info .home { align-items: center; }
.smallcard_info .home p { font-size: 12px; }
.smallcard_info .home svg { width: 15px; height: 15px; }
.small-card-deatils p,
.small-card-deatils span { font-size: 12px !important; }
.small_absolutebutton { left: 15px; top: 15px; }
.small_absolutebutton .btn { background: #21114D !important; border: none !important; border-radius: 8px !important; font-weight: 600 !important; font-size: 12px !important; line-height: 20px !important; padding: 5px 8px !important; }
@media only screen and (max-width:1399px) {
 .fs-sm-auto { font-size: 10px; }
.small-grup svg { border: 1px solid rgba(33, 17, 77, 0.1); padding: 5px 5px; width: 25px; height: 25px; border-radius: 50%; background-color: #fff; margin-left: 3px !important; }
 }
/* Approved page  */
.ApprovdTopDiv { display: flex; justify-content: space-between; padding: 20px 0px; flex-wrap: wrap; }
.aprvname { display: flex; gap: 18px; }
.showedact span { position: relative; color: #6341E0; font-size: 12px; font-weight: 500; line-height: 18px; margin-left: 15px; }
.showedact span:after { position: absolute; content: " "; width: 5px; height: 5px; border-radius: 50%; background: #6341E0; left: -12px; top: 4px; }
.Perslhead h2 { color: #21114D; font-size: 20px; font-weight: 600; line-height: 28px; }
.Perslhead h2 svg { color: #6341E0; }
.tableHeading { display: grid; grid-template-columns: 1fr 1fr 1fr; border: 1px solid #E0DEF7; height: 48px; align-items: center; }
.tableHeading h4 { margin: 0; text-align: left; padding-left: 20px; border-right: 1px solid #E0DEF7; height: 100%; display: flex; align-items: center; color: #21114D; font-size: 12px; font-weight: 500; line-height: 18px; }
.tableBody { display: grid; grid-template-columns: 1fr 1fr 1fr; height: 48px; align-items: center; background-color: #fff; border: 1px solid #E0DEF7; border-top: none; }
.tableBody .input_field { display: block !important; height: 100%; padding-left: 0; }
.tableBody .input_field>input { padding-left: 20px; border-right: 1px solid #E0DEF7; height: 100%; display: flex; align-items: center; color: #21114D; font-size: 12px; font-weight: 500; padding-right: 0; line-height: 18px; box-sizing: border-box; }
.tableBody input { margin: 0; text-align: left; padding-left: 20px; border-right: 1px solid #E0DEF7; height: 100%; display: flex; align-items: center; color: #21114D; font-size: 14px; font-weight: 400; line-height: 20px; border-radius: 0px; }
.rightbtn { display: flex; gap: 20px; align-items: center; }
.pretable { /* border: 1px solid #E0DEF7; */
  background: #fff; height: 100%; }
.transdiv { display: grid; grid-template-columns: 85% 15%; border-top: 1px solid #E0DEF7; background: #F7F7FD; height: 48px; align-items: center; padding-left: 20px; }
.transdiv h6 { display: flex; align-items: center; color: #21114D; font-size: 12px; font-weight: 500; line-height: 18px; }
.transdiv h6:nth-child(1) { border-right: 1px solid #E0DEF7; height: 100%; }
.transdiv h6:nth-child(2) { text-align: center; justify-content: center; }
.predivtable { display: grid; grid-template-columns: 85.2% 14.8%; border-top: 1px solid #E0DEF7; height: 100%; align-items: center; /* padding-left: 20px; */ }
.predivtable h6 { align-items: center; color: #21114D; font-size: 12px !important; font-weight: 500 !important; line-height: 18px !important; padding: 16px 32px 16px 16px; margin: -2px 0; }
.predivtable h6 svg { color: #6341E0; }
.predivtable h6:nth-child(1) { border-right: 1px solid #E0DEF7; }
.predivtable h6:nth-child(2) { display: flex; justify-content: center; }
.preaccordion { outline: none; width: 95%; border: none; box-shadow: none; }
.precollapse { outline: none; border: none; box-shadow: none; font-weight: 600; padding: 0 !important; line-height: 20px; font-size: 14px; }
.preaccorditem { border: none; }
.preaccorditem .id_tr td { font-size: 12px !important; }
.preaccordion .id_tr td .MuiSvgIcon-root { width: 0.8em; height: 0.8em; }
.preaccorditem .accordion-collapse { padding: 0 2rem; width: 90%; max-width: 560px; }
.preaccorditem .accordion-collapse .accordion-body { padding: 0 !important; }
.preaccorditem .tag { border-radius: 10px !important; }
.preaccorditem .TagDivs { padding-left: 0; }
.preaccordion .TagDivs input { padding-left: 0; }
.preaccorditem .tag h6 { padding: 0px 7px; }
.preaccorditem .form-check-input { width: 16px; height: 16px; margin-top: 0 !important; }
.preaccorditem .form-label { font-size: 12px !important; margin-bottom: 0 !important; }
.preaccorditem .checkbox_gurp { padding: 10px; grid-gap: 8px; gap: 8px; }
.preaccorditem .SearchLook .MuiInputBase-input { background: url(../../Images/search1.png) !important; background-size: 16px 16px !important; background-repeat: no-repeat !important; padding: 0px 35px; background-position: 0px center !important; padding-left: 35px !important; }
.preaccorditem .Setting_gridBox>.MuiGrid-container>.MuiGrid-item { padding-top: 0.5rem !important; }
.preaccorditem .Setting_gridBox { padding-bottom: 10px !important; }
.preaccorditem .Setting_gridBox p { font-size: 12px !important; line-height: 18px !important; }
.precollapse:not(.collapsed) { color: #21114D; background-color: transparent; box-shadow: none; }
.precollapse:focus { z-index: 3; border-color: transparent; outline: 0; box-shadow: none; }
.PreForm { display: grid; grid-template-columns: auto 1fr; align-items: center; grid-gap: 20px; }
.PreForm input,
.PreForm .MuiSelect-select { padding: 5px 14px; font-size: 10px !important; line-height: 18px !important; padding: 3px 14px; border-radius: 20px; }
.PreForm .MuiSelect-select,
.preaccorditem .input_field .MuiSelect-select,
.preaccorditem .input_field input,
.preaccorditem .SearchLook #gsearchlook { font-size: 10px !important; line-height: 16px !important; padding: 3px 14px; border-radius: 20px; }
.PreForm .form-label { font-size: 10px !important; line-height: 20px !important; }
.PreForm .MuiSelect-select::after,
.preaccorditem .input_field .MuiSelect-select::after { top: 5px; background-size: 10px !important; }
.PreForm p { margin-bottom: 0 !important; }
.Refn { text-align: left; color: #21114D !important; font-size: 14px !important; font-weight: 400 !important; line-height: 20px !important; margin: 0 !important; }
.idRef { text-align: left; color: #21114D !important; font-size: 14px !important; font-weight: 400 !important; line-height: 20px !important; border: none !important; padding: 0 !important; }
.Refn span { font-weight: 600; text-decoration-line: unset; }
.id_tr { display: grid; grid-template-columns: 1fr 1fr; align-items: center; font-size: 10px; }
.id_tr td span { color: #6341E0; }
.id_tr td { color: #21114D; font-size: 14px; font-weight: 500; line-height: 20px; }
.banktext { display: flex; align-items: center; gap: 15px; }
.banktext h4 { color: #21114D; font-size: 12px; font-weight: 500; line-height: 150%; text-align: left; margin: 0 !important; }
.banktext svg { width: 0.8em; height: 0.8em; }
.lmbtn { color: #6341E0; font-size: 16px; font-weight: 600; line-height: 16px; border: none; box-shadow: none; background: none; }
.preaccorditem .lmbtn { font-size: 13px !important; }
.Thhead { text-align: center !important; }
.updateprop { padding-bottom: 0px !important; }
/* FaQ Section  */
.FaQ_left h2 { color: #000; font-size: 32px; font-weight: 700; line-height: 120%; }
.Faq_Accord { padding: 50px 0px 0px 0px; }
.Faq_AccordItems { background: transparent; border: none; border-top: 1px solid #D7DEF0; border-bottom: 1px solid #D7DEF0; border-radius: 0px !important; box-shadow: none; }
.FaQaccord_button { background: transparent; color: #000; font-size: 18px; font-weight: 700; }
.FaQaccord_button:not(.collapsed) { background-color: transparent; box-shadow: none; color: #000; }
.FaQaccord_button:focus { border-color: #D7DEF0; box-shadow: none; }
.faqAccorBody p { color: rgba(60, 60, 67, 0.85); font-size: 16px; font-weight: 400; }
.id_section h4 { text-align: left; margin-bottom: 10px; margin-top: 0px; }
.frm_ro_w { display: grid; grid-template-columns: 1fr 1fr; grid-gap: 20px; margin-bottom: 15px; }
.frm_ro_w input,
.frm_ro_w select { width: 100%; border: 1px solid #E0DEF7; height: 30px; padding: 6px 15px; margin-top: 8px; border-radius: 8px; background-color: #fff; }
.frm_ro_w .MuiSelect-select { height: 22px !important; margin-top: 8px; }
.frm_ro_w .MuiSelect-nativeInput { margin: 0px; height: 100%; }
.frm_ro_w .input_field .MuiSelect-select::after { top: 25px; }
.frm_ro_w.sngl_row { grid-template-columns: auto; }
.form_gr_d { padding-bottom: 35px; }
.frm_ro_w label { font-size: 14px; }
.id_section h4.small_h { font-size: 14px; padding-top: 12px; }
.frm_ro_w.triple_row { grid-template-columns: 1fr 1fr 1fr; }
.form_gr_d.p_add { padding-bottom: 2px; }
.doc_det .clickUpload { margin-bottom: 30px; }
.detailsHeading.bank_stat h4 { font-size: 25px; color: #21114D; }
.bankProduct img { width: 50px; height: auto; }
.fillBTN { display: flex; gap: 10px; }
.fillBTN:hover { background: #6341E0 !important; border: 1px solid #6341E0 !important; color: #fff !important; }
.up_loa_d { font-size: 12px; margin-top: -10px; text-align: center; }
.compl_ete h4 { font-size: 18px; color: #21114D; text-align: left; margin: 0px; }
.compl_ete .cre_dt { font-size: 14px; }
.Listings.pb-0.for_btns { padding-bottom: 100px !important; height: calc(100vh - 78px); }
.for_breadcr .nav.nav-pills { margin-left: 0px; }
.for_iframe { min-height: calc(100vh - 373px); width: 100%; overflow: auto; }
.for_iframe iframe { width: 100% !important; min-width: 800px !important; }
.btns_group { text-align: center; padding: 20px 0; }
.btn.fotter_btn { min-width: 300px; }
.for_breadcr { padding: 0 40px; }
.Property.cont_navi { padding-bottom: 20px !important; }
.Listings.Property.conTrac_t { min-height: calc(100vh - 200px); padding-bottom: 30px; display: flex; align-content: center; align-items: center; }
.conTrac_t_inner { max-width: 500px; margin: auto; }
.conTrac_t_inner h1 { font-size: 38px; font-weight: 400; line-height: 38px; }
.conTrac_t_inner h1 strong { font-weight: 600 !important; }
.conTrac_t_inner h6 { font-size: 14px; padding: 16px 0 38px 0; }
.conTrac_t_inner .btn.btn-primary { width: 100%; }
.iframe_hide { width: 100%; height: 61px; background: #f7f7fd; position: absolute; z-index: 99; top: 67px; }
.for_iframe { position: relative; margin-top: -60px; }
.btn_gurpModal.bg-white { position: fixed; bottom: -37px; width: 562px; right: -32px; padding-top: 23px !important; }
.bg_white_inner { background: #fff !important; display: block; padding: 29px 41px; width: calc(100% - 73px); }
.listingmodal_details .btn_gurpModal button { position: relative; }
/* .slick-track { width: 100% !important; }
*/
.id_section button { border: none; background: transparent; color: #6341E0; }
.AgentDiv.verif { margin: auto; margin-top: auto; margin-top: 10% !important; width: 95%; height: auto !important; }
.AgentDiv.verif .agntBtns { display: flex; justify-content: center; }
/* responsive */
@media screen and (max-width:1500px) {
 .frm_ro_w.triple_row { grid-template-columns: 1fr 1fr; }
 }
/* @media only screen and (max-width: 991px) and (min-width: 767px) {
 }
*/
@media only screen and (max-width: 1200px) {
 form { width: 400px; }
.map-list-nearby { position: fixed; top: 80px; right: 290px; display: flex; gap: 10px; z-index: 1; }
 }
@media only screen and (max-width: 1100px) {
 .map-list-nearby { position: fixed; top: 80px; right: 235px; display: flex; gap: 10px; z-index: 1; }
 }
@media only screen and (max-width: 991px) {
 /* .sidebar { display: none; }
*/
  .main_sec { width: 100%; }
.Property { width: 100%; }
.EmptyProperties { width: 100%; padding: 20px; }
.TenantLoginMain { grid-template-columns: 1fr; }
.Property .nav-pills .togle-btn { margin-left: auto; }
.togle-btn { display: block !important; }
.togle-btn img { height: 45px; }
.nav-pills { gap: 0px; }
.ReviewProperty.Property.updateprop { padding-bottom: 0px !important; }
 }
@media screen and (max-width:991px) {
 .map-view { margin-top: 0; min-height: 100vh; }
.map-list-nearby { position: absolute; top: 10px; right: 10px; }
.map-wrap { display: flex !important; flex-wrap: wrap !important; }
 }
@media screen and (max-width:768px) {
 .btn { background: #6341E0 !important; border: 1px solid #6341E0 !important; border-radius: 8px !important; box-shadow: 0px 1px 2px rgb(16 24 40 / 5%); padding: 9px 12px !important; text-transform: inherit !important; font-weight: 600 !important; font-size: 14px !important; line-height: 24px !important; color: #FFFFFF !important; height: -moz-fit-content !important; height: fit-content !important; }
.map-view { margin-top: 0; min-height: 100vh; }
.map-list-nearby { position: absolute; top: 10px; right: 10px; }
.switch-view .form-select { font-size: 11px !important; padding-top: 10px; padding-bottom: 10px; width: 98px; }
.Listings.pb-0.for_btns { padding-left: 20px; padding-right: 20px; }
.for_breadcr { padding: 0 20px; }
.conTrac_t { padding-left: 20px; padding-right: 20px; }
.AgentDiv.verif { margin-top: 5% !important; width: 95%; }
 }
.verifi_list a { color: #6341E0; font-size: 16px !important; font-weight: 600; }
.verifi_list { display: grid; }
.abc.toggle-details p { font-size: 10px; /* padding-bottom: 3px; */
  line-height: 20px; }
/* .doc-detail .toggle-head h6 { padding-bottom: 15px; } */
.owl-carousel .tenant_AppCard .card_details { min-height: 80px; overflow: auto; max-height: 80px; }
.gm-style .relativee_FeaturedImg { position: relative; max-width: 280px !important; }
@media only screen and (max-width: 578px) {
 .slick-dots { position: absolute; /* bottom: -48px !important; */
    display: block; width: 90% !important; /* margin: auto !important; */
    left: 5%; padding: 0; margin: 0; list-style: none; text-align: center; }
.frm_ro_w.triple_row { grid-template-columns: auto; }
.frm_ro_w { grid-template-columns: auto; }
.bankProduct img { width: auto; }
 }
@media only screen and (max-width: 540px) {
 h2 { font-size: 24px; }
form { width: 100%; padding: 0 30px; }
.nav-pills { margin: 50px 15px 0; }
.Property { width: 100%; }
 }
.MuiModal-root iframe { left: calc(50% - 250px); position: absolute; width: 96%; max-width: 500px; top: 8%; border-radius: 10px; }
@media only screen and (max-width: 540px) {
 .btn_gurpModal.bg-white { left: -21px; width: 100%; }
.listingmodal_details .btn_gurpModal button { width: 100%; }
.bg_white_inner { width: 100%; }
 }
@media only screen and (max-width: 430px) {
 .slick-dots { bottom: -48px !important; }
 }
.blank_image { background: #F7F7FD; padding: 10px; height: 45px; width: 46px; border: 1px solid #E0DEF7; border-radius: 50%; }
.user_details h5 p span p { margin-bottom: 0px !important; }
.profile_mail { margin-bottom: 0px; }
.property_req .checkbox_gurp { display: flex; padding: 0px; border: none; }
.property_req .checkbox_gurp .MuiFormGroup-root { grid-template-columns: 1fr 1fr !important; display: grid !important; width: 100%; grid-gap: 10px; align-items: baseline; }
.property_req .checkbox_gurp .MuiFormGroup-root>div { display: flex; justify-content: center; }
.property_req .checkbox_gurp .MuiFormGroup-root>div p { margin: 0px !important; line-height: 18px !important; }
.date_picker input { background: url(../../Images/datepick.png) !important; background-repeat: no-repeat !important; background-position: calc(100% - 6px) center !important; padding: 3px 11px; width: 100%; border-radius: 5px; border: 1px solid rgba(33, 17, 77, 0.10) !important; background-color: #fff !important; }
.date_picker .form-check-input[type="checkbox"] { margin: 0px 4px 0 0; }
.Right_sidebar .test { overflow: auto; padding-bottom: 100px; }
.text-Elipses { white-space: nowrap; height: 15px; display: block; width: 240px; overflow: hidden; text-overflow: ellipsis; }
.Right_sidebar .test .btn_gurp.reset_apply { background: #fff; position: fixed; bottom: 0px; width: 226px; display: flex; justify-content: space-between; padding: 25px 0; }
.dropdown.ad_filter .dropdown-menu { width: 100%; padding: 0px; }
.dropdown.ad_filter li>div { display: flex; }
.dropdown.ad_filter li>div p { margin: 0px !important; }
.dropdown.ad_filter .btn { border-radius: 5px !important; border: 1px solid rgba(33, 17, 77, 0.10) !important; background: #fff !important; color: #21114D !important; font-size: 14px !important; }
.SearchLook .MuiInputBase-input { background: url(../../Images/search1.png) !important; background-repeat: no-repeat !important; padding: 0px 35px; background-position: 0px center !important; }
.SearchLook svg { display: none; }
.search_look::after { display: none !important; }
.search_look::before { display: none !important; }
.SearchLook { margin-top: 10px; }
.mo_number h6 { margin: 0px; position: absolute; z-index: 5; left: 10px; color: #ccc; line-height: 40px; border-right: 1px solid #ccc; padding-right: 10px; margin-bottom: 0px !important; top: 28px; font-size: 15px; }
.mo_number { position: relative; }
.mo_number .input_field input { padding-left: 53px; padding-top: 0px; padding-bottom: 0px; height: 43px; }
.ph_no { position: relative; }
.ph_no h6 { margin: 0px; position: absolute; z-index: 5; left: 0px; color: #ccc; border-right: 1px solid #ccc; margin-bottom: 0px !important; font-size: 10px !important; line-height: 16px !important; padding: 3px 14px; }
.ph_no .input_field input { padding-left: 53px; }
/* res */
.form-check.radi_o .form-label { line-height: 22px !important; margin-bottom: 0px !important; }
.form-check.radi_o { margin-top: 7px; }
.top_bar .Search_Filters .input-group { min-width: 372px; }
.top_bar .input-group-text { background: transparent; }
.CurrntTopDash .Price_bond .MuiInputBase-input { margin-bottom: 0px !important; }
.AddLivingForms .Price_bond .MuiInputBase-input { margin-bottom: 0px !important; }
.PropertyInformation .Price_bond .MuiInputBase-input { margin-bottom: 0px !important; }
.PropertyAddress .Price_bond .MuiInputBase-input { margin-bottom: 0px !important; }
.SelectProperty .Price_bond .MuiInputBase-input { margin-bottom: 0px !important; }
.DetailsData .Price_bond .MuiInputBase-input { margin-bottom: 0px !important; }
.top_bar .input-group .form-control { border-left: none !important; }
.modal_view .Price_bond .MuiInputBase-input { margin-bottom: 0px !important; }
.set_ting button.btn-close { position: absolute; right: 8px; top: 9px; }
body .address_modal.modat_sett .modal_view { top: 10%; height: 90% !important; }
body .address_modal.modat_sett .modal_box { width: 95%; max-width: 500px; height: auto; }
.Agent_account_Data #progressbar li.activedot::before { content: "." !important; font-size: 61px !important; line-height: 1px !important; background: transparent !important; border: 1px solid #6341E0 !important; color: #6341E0; font-weight: bold; }
.date_lease .react-datepicker { padding-top: 80px; }
.date_lease .date_opt { position: absolute; top: 13px; width: calc(100% - 24px); }
.date_lease .react-datepicker__navigation { top: 90px !important; }
.date_lease .date_opt .input_field { font-size: 16px; }
.slectplan_bg { display: flex; justify-content: center; align-items: center; flex-direction: column; gap: 50px; min-height: 100vh; background: #21114D; backdrop-filter: blur(8px); background-image: url(/src/Images/bggShape.png); background-size: 100% 100%; background-position: center; padding: 35px 0; }
.plans_section { position: relative; width: 92%; margin: auto; max-width: 780px; }
.plans_section .white_box { background: #fff; padding: 25px 0; border-radius: 12px; border-bottom: 1px solid #666; box-shadow: 0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08); }
.box_head img { width: 50px; }
.box_head { padding: 0 25px; display: grid; grid-template-columns: 50px auto; gap: 16px; }
.box_head h5 { color: #21114D; font-size: 20px; font-style: normal !important; font-weight: 600; line-height: 30px; }
.box_head p { color: #21114D; font-size: 16px; font-style: normal !important; font-weight: 400; line-height: 24px; }
.box_head a { color: #6341E0; font-size: 16px; font-style: normal !important; font-weight: 600; line-height: 16px; }
.main_plan_logo { padding-bottom: 22px; text-align: center; }
.container { display: block; position: relative; padding-left: 35px; margin-bottom: 12px; cursor: pointer; font-size: 22px; -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }
.contain input { position: absolute; opacity: 0; cursor: pointer; height: 0; width: 0; }
.checkmark { position: absolute; top: 0; left: 0; height: 25px; width: 25px; background-color: #eee; }
.contain:hover input~.checkmark { background-color: #ccc; }
.contain input:checked~.checkmark { background-color: #2196F3; }
.checkmark:after { content: ""; position: absolute; display: none; }
.contain input:checked~.checkmark:after { display: block; }
.contain .checkmark:after { left: 9px; top: 5px; width: 5px; height: 10px; border: solid white; border-width: 0 3px 3px 0; -webkit-transform: rotate(45deg); -ms-transform: rotate(45deg); transform: rotate(45deg); }
.box_body { padding: 25px 25px; border-top: 1px solid #ddd; margin-top: 20px; display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-gap: 15px; border-bottom: 1px solid #ddd; }
.check_b { border-radius: 12px; border: 1px solid #E0DEF7; background: #FFF; box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05); padding: 15px; position: relative; cursor: pointer; }
.check_b .form-check-input { border-color: #ddd; border-radius: 50% !important; position: absolute; top: 8px; right: 0; }
.check_b .form-check-input:checked { border-color: #6341E0 !important; }
.check_b h3 { color: #21114D; font-size: 24px; font-weight: 600; line-height: 32px; padding-right: 24px; }
.check_b h4 { color: #21114D; font-size: 16px; font-weight: 600; line-height: 20px; text-align: left; margin: 15px 0 !important; }
.check_b p { color: #21114D; font-size: 14px; font-weight: 400; line-height: 20px; }
.check_b h5 { color: #21114D; font-size: 16px; font-weight: 600; line-height: 20px; display: flex; gap: 10px; }
.check_b h5 img { height: 20px !important; }
.check_b.active { border-color: #6341E0; }
.box_footer { display: flex; justify-content: space-between; padding: 25px 25px 0 25px; }
.box_footer div { gap: 9px; display: flex; }
.btn3.cha_t { border: 1px solid #6341E0 !important; color: #6341E0 !important; display: flex; gap: 10px; }
.Agent_account_Data #progressbar li.activedot::before { content: "." !important; font-size: 66px !important; line-height: 1px !important; background: transparent !important; border: 2px solid #6341E0 !important; color: #6341E0; font-weight: bold; }
.btns_grp { display: flex; gap: 20px; padding: 20px 0; }
.heading_section.wth_btn { display: flex !important; justify-content: space-between; flex-wrap: wrap; }
.btn_cntr { display: flex; justify-content: center; padding-top: 20px; gap: 15px }
.heading_section h5 { padding: 25px 0; }
.Setting_gridBox.grd_sz .pro_grd>div { width: 100% !important; max-width: 100% !important; }
.Setting_gridBox.grd_sz .pro_grd { display: grid; grid-template-columns: 280px auto; grid-gap: 0px !important; max-width: 100% !important; }
.col_height>div { height: 100%; }
.table .listed span { margin-top: -20px; line-height: 20px !important; }
/* .listed { display: flex; padding: 6px 10px; align-items: center; align-self: stretch; font-size: 13px; border-radius: 16px; font-weight: 500; gap: 6px; line-height: 13px; }
backtoorignal*/
.listed { display: inline-flex; gap: 4px; padding: 2px 8px 2px 6px; align-items: center; align-self: stretch; font-size: 13px; border-radius: 16px; font-weight: 500; gap: 6px; line-height: 13px; }
.listed.g_reen { background: rgba(33, 170, 72, 0.05); color: #21AA48; }
.listed.b_lue { background: #F7F7FD !important; color: #6341E0 !important; }
.listed.b_lack { background: #F7F7FD !important; color: #21114D !important; }
.listed.light_blue { background: #F7F7FD !important; color: #B1A0EF !important; }
.listed.o_range { background: rgba(255, 122, 0, 0.05) !important; color: #FF7A00 !important; }
.listed.light_green { background: rgba(65, 214, 224, 0.05) !important; color: #41D6E0 !important; }
.RightLogin { border-radius: 80px 0px 0px 80px !important; }
.RightAgentLogin { border-radius: 80px 0px 0px 80px !important; height: 100% !important; }
.LogInForm form { margin: 0px auto !important; }
.AgentLogIn { margin-top: 0px !important; }
.LeftAgentLogin { padding: 45px 45px 17px 45px !important; }
.pro_grd.mob_code .MuiGrid-root h6 { left: 24px; top: 18px; }
.pro_grd.mob_code .MuiGrid-root { position: relative; }
.nav.nav-pills.nav_r_ow { display: flex; justify-content: space-between; align-items: center; gap: 20px; }
.nav.nav-pills.nav_r_ow .nav_row { display: flex; gap: 5px; }
.btnrow.proper_ty { margin: 0px; gap: 10px; display: flex; }
.review_doc_table tr { border: 1px solid #E0DEF7; }
.review_doc_table tr th { background: #F7F7FD; padding: 12px 16px 12px 32px; border: 1px solid #E0DEF7; }
.review_doc_table tr th:last-child { text-align: end; }
.review_doc_table tr td:last-child { text-align: end; }
.review_doc_table tr td svg { color: #6341E0; }
.review_doc_table tr td { background: #fff; border: 1px solid #E0DEF7; padding: 12px 16px 12px 32px; }
.table-responsive.tbl_gr_d .Properties_table tr { grid-template-columns: 1fr 1fr 1fr 1fr; }
.table-responsive.tbl_gr_d .btn_grp { display: flex !important; }
.white_box .clss { position: absolute; right: 15px; top: 9px; color: #fff; font-weight: 600; font-size: 20px; border: 1px solid #ccc; border-radius: 50% !important; width: 30px; height: 30px; text-align: center; background: #6341e0; }
.Paymentmethod__headSec .vis { width: 60px; margin-top: 6px; }
.Paymentmethod__headSec .mail { margin-top: 11px; }
/* .Paymentmethod__headSec > div { display: grid; align-items: center; }
*/
/* responsive 20/06/2023 */
/* mobileheader */
.mobile_header .mobilelogo { width: auto; height: 30px !important; }
.mobile_header .rt_side .profile_img { width: 25px; height: 25px; }
.mobile_header .togle-btn { display: block !important; }
.mobile_header { display: flex; justify-content: space-between; padding: 10px 15px; align-items: center; display: none; }
.mobile_header .rt_side { display: flex; gap: 13px; align-items: center; }
.mobile_header .rt_side button img { height: 25px; width: 25px; }
.modelCross .crossed { width: 100%; display: flex; font-size: 22px; color: #6341e0; justify-content: end; font-weight: 900; }
.modelCross .crossed:hover { background-color: transparent; }
.singleGroup { grid-template-columns: 1fr !important; margin: 0px 20px !important; }
.singleGroup p { margin-bottom: 0px !important; }
.singleGroup .Formgrp { display: flex !important; gap: 10px !important; align-items: center !important; }
.singleGroup .Formgrp .check input { border-radius: 50% !important; margin: 0px !important; }
/* others */
.RightAgentForgot,
.AgentSliders,
.RightAgentLogin,
.RightLogin { background-size: cover !important; background-position: center right !important; }
.desklogo { display: block !important; }
.moblogo { display: none !important; }
.desk_logo { display: none; }
.Price_bond .MuiInputBase-input { margin-bottom: 15px; }
.SettingTeam tr { grid-template-columns: 35px auto 100px 120px !important }
.browser_property img { object-fit: cover; }
.btnrow { margin-top: -44px; }
.watchlist_table { min-width: 950px !important; }
.Search_Filters { gap: 10px; }
.AddLivingArranges { position: relative; height: 375px; }
.AddLivingArranges .MuiTabs-root.addlivingTabGurp { position: absolute; bottom: -7px; left: 0; right: 0; }
.ApplicationModal .modal_box { max-width: 600px !important; width: 100% !important; }
.for_feature { position: relative; }
.for_feature h5 { position: absolute; bottom: 15px; left: 15px; border-radius: 16px; border: 1px solid #E0DEF7; background: #FFF; padding: 5px 14px; color: #6341E0; font-size: 14px; font-weight: 500; line-height: 20px; }
.for_feature h5 img { width: 18px !important; margin-right: 4px; }
.slide_images img.rounded { border: 1px solid #E0DEF7; padding: 2px; }
.for_feature img.rounded { border: 1px solid #E0DEF7; padding: 2px; }
.ReviewProperty.Property .Review_card h4 { text-align: left; margin-bottom: 10px; }
.onboardStep.ten_en a:hover { background: transparent !important; }
.Agent_bgg_data { display: block !important; }
.Agent_bgg_data .RegisterForm { margin-left: 500px; width: calc(100% - 500px); }
.Agent_bgg_data .LeftAgentAccount { position: fixed; width: 500px; min-height: 100vh; padding-bottom: 100px; top: 0; bottom: 0; }
.Agent_bgg_data .LeftAgentAccount .creatAgentLogin { position: absolute; bottom: 0px; width: 86%; background: #21114d !important; padding: 10px 0; }
.Listings .heading_section { display: grid; grid-template-columns: 1fr auto auto; grid-column-gap: 12px; }
.table-responsive { padding-bottom: 32px !important; }
.ONBoardingStep { display: block !important; }
.ONBoardingStep .onBoardDash { margin-left: 500px; width: calc(100% - 500px); min-height: 100vh !important; }
.ONBoardingStep .onboardStep.ten_en { position: fixed; width: 500px; min-height: 100vh; padding-bottom: 100px; top: 0; bottom: 0; z-index: 999 !important; }
.ONBoardingStep .onboardStep.ten_en .onboardfoter { position: absolute; bottom: 0px; width: 86%; background: #21114d !important; padding: 10px 0; }
.Agent_account_Data { overflow: auto !important; /* height: calc(100vh - 226px); */
  min-height: 400px; display: block !important; padding-bottom: 60px; height: auto; }
/* .AddLivingArranges { padding-bottom: 50px !important; }
*/
.dashlivingArangement .AddLivingArranges .MuiTabs-root.addlivingTabGurp { bottom: 100px !important }
body .address_modal .modal_view { transform: none !important; position: absolute !important; top: 10% !important; height: 80% !important; bottom: auto !important; left: 0; right: 0; margin: auto; width: 100%; overflow: auto !important; }
#mui-component-select-leavingReason { margin-bottom: 37px !important; }
.imLooking .SearchLook input { border: none !important; }
.profile_noimage { height: 70px; width: 70px; display: flex; align-items: center; justify-content: center; }
.PropertiesTenant .Review_card .slide_images { margin-top: 55px !important; }
.imgOuter.list_rows { background: #fff; bottom: -35px; }
.dissab_le { opacity: .4; cursor: none; }
.absolute_FeaturedImg { bottom: 14px; }
.Search_Filters.for.resp { max-width: 480px; }
.user_details { grid-template-columns: auto !important; justify-content: center !important; grid-gap: 4px !important; color: #21114D !important; font-size: 16px !important; }
.user_details a { margin: 0px !important; padding: 0px 4px !important; text-align: center; justify-content: center; }
.after_log { display: flex; gap: 7px; align-content: center; align-items: center; }
.blank_image { height: 37px; width: 37px; display: flex; align-items: center; }
.after_log button { padding: 5px; }
.owl-wrapper { margin: 0 auto; }
.owl-carousel .owl-stage { margin: auto; }
.slider-inner .owl-theme .owl-dots .owl-dot span { width: 8px; height: 8px; margin: 5px 5px; }
.slider-inner .owl-theme .owl-nav { position: absolute; bottom: 0px; left: 0; right: 0; display: flex; justify-content: center; gap: 200px; }
.slider-inner .owl-theme .owl-nav button span { width: 20px; height: 20px; display: flex; align-items: center; justify-content: center; background-color: #21114D; color: #fff; border-radius: 4px; position: relative; font-size: 15px; z-index: 1; }
.owl-theme .owl-dots { text-align: center; -webkit-tap-highlight-color: transparent; position: relative; z-index: 2; width: fit-content; margin: 0 auto; }
/* .navigation-custom { position: relative; bottom: 0; }
*/
.SearchLook { display: flex; align-items: center; background:
    #FFFFFF; border: 1px solid #E0DEF7; border-radius: 8px; padding: 5px 14px 5px 14px; color: #6341E0; }
.form-textInput { padding: 10px 13px 10px 13px; gap: 8px; border-radius: 8px; border: 1px solid #E0DEF7 !important; background: #FFFFFF !important; width: 100%; line-height: 20px; }
.form-textInput:focus { border: 1px solid #E0DEF7 !important; background: #FFFFFF !important; box-shadow: none !important; outline: 0 !important; }
.emailInputbx { width: 100%; }
.addapplicantInput { display: grid; width: 100%; gap: 10px; grid-template-columns: 1fr 72px; }
.remove-btn { background: none !important; outline: 0 !important; border: none !important; color: #6341E0; }
.email-list { padding: 10px 0 20px 0; display: flex; flex-flow: column; gap: 15px; }
.email-item { display: flex; justify-content: space-between; gap: 10px; align-items: center; color: #21114D; font-family: Inter; font-size: 14px; font-weight: 500; line-height: 20px; border-radius: 8px; border: 1px solid #E0DEF7; background: #FFFFFF; padding: 12px 12px; }
.joint_section .email-item { flex-wrap: wrap; }
.emailupButtons { display: flex; gap: 10px; justify-content: end; /* flex-wrap: wrap; */
  text-align: right; align-items: center; margin-left: auto; }
.emailupButtons .MuiSvgIcon-root { color: #6341E0; }
.listUsername { display: flex; gap: 10px; align-items: center; @media (max-width:540px) {
 min-width: 100%; width: 100%; overflow: auto; padding: 0.5rem 0; }
 }
.listUsername .circlebg-purple { max-width: 50px; height: 50px; width: 50px; margin: 0; @media (max-width:767px) {
 max-width: 30px !important; height: 30px !important; width: 30px !important; min-width: 30px !important; }
 }
.listUsername .circlebg-purple img { width: 60% !important; height: 60% !important; }
.modal-common { @media (max-width:767px) {
 padding: 10px !important; }
 }
.modal-common .prev-btn { border: 1px solid #E0DEF7 !important; background: #FFFFFF !important; box-shadow: 0px 1px 2px 0px #1018280D !important; width: 100%; color: #21114D; min-height: 40px; padding: 10px 16px 10px 16px; gap: 8px; border-radius: 8px; }
.disabled-btn { opacity: 0.6; }
.modal-common .next-btn { border: 1px solid #E0DEF7 !important; width: 100%; color: #fff; background: #6341E0; box-shadow: 0px 1px 2px 0px #1018280D !important; min-height: 40px; padding: 10px 16px 10px 16px; gap: 8px; border-radius: 8px; }
.modal-common { width: 600px !important; padding: 25px; border-radius: 12px; }
.main-modal-common .MuiBackdrop-root { backdrop-filter: blur(10px); -webkit-backdrop-filter: blur(10px); background-color: rgb(33 17 77 / 60%) !important; }
.apply-radio { display: flex; flex-direction: column; gap: 10px; justify-content: center; align-items: center; text-align: center; }
.radio-container { display: flex; width: auto; gap: 20px; color: #21114D; margin: auto; justify-content: center; }
.circlebg-purple { width: 84px; height: 84px; border-radius: 100%; display: flex; margin: auto; align-items: center; justify-content: center; background: #E0DEF7; }
.circlebg-purple img { max-width: 100%; height: auto; }
.ft_menu {
  list-style: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  gap: 17px;
  flex-wrap: wrap;
  font-size: 16px;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
  color: #000929;
}
.ft_menu a {
  color: #000929;
  padding: 0 10px;
}
.footerl_inner p.copyrt {
  display: flex;
  justify-content: center;
  gap: 10%;
  padding-top: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
}
.footerl_inner {
  text-align: center;
}
.ft_menu {
  margin-top: 38px;
}
.footerl {
  background: #f7f7fd;
  padding: 33px 0;
}
.contact_form {
  box-shadow: 0px 4px 4px 0px #00000040;
  background: #fff;
  padding: 30px;
  border-radius: 20px;
}
.inner_form {
  margin: 0px;
}
.inner_form label {
  font-size: 14px;
  font-weight: 700;
  line-height: 14.63px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: grid;
  width: 100%;
  grid-gap: 6px;
}
.inner_form {
  display: grid;
  grid-gap: 30px;width: 100%;padding: 0px !important;
}
.inner_form input, .inner_form textarea {
  box-shadow: 0px 2px 2px 0px #0000002E;
  border: 1px solid #DDDDDD; width: 100%;
  height: 43px;
  padding: 6px 14px; border-radius: 6px;
}
.inner_form textarea { height: 150px;}


.inner_form input.na_me{ background-image: url(../../Images/Icons1.png);background-repeat: no-repeat;
  background-position: 9px center;
  padding-left: 43px;}
.inner_form input.na_me:focus-visible{ background-image: url(../../Images/Icons.png); border-color: #51b7ff !important; outline: none;}
.inner_form input.ema_il{ background-image: url(../../Images/Email.png);background-repeat: no-repeat;
  background-position: 9px center;
  padding-left: 43px;}
.inner_form input.ema_il:focus-visible{ background-image: url(../../Images/Email1.png); border-color: #51b7ff !important; outline: none;}
.inner_form textarea:focus-visible{border-color: #51b7ff !important; outline: none;}


.form_btns button {
  background: #6341E0;
  color: #fff;
  border: none;
  font-size: 15px;
  font-weight: 700;
  line-height: 31.07px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  padding: 8px 26px;
  border-radius: 12px;
  text-transform: uppercase;
}
.form_btns a {
  font-size: 16px;
  font-weight: 700;
  line-height: 14.63px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #6341e0;
}
.form_btns {
  display: flex;
  align-items: center;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: end;padding-top: 25px;
}
.landining_main_grid.story .landining_left {
  max-width: 744px;
}
.landining_main_grid.story {
  display: grid;
  grid-template-columns: auto !important;
}
.css-faujvq{ padding: 8px !important; }
/* responsive */

@media (min-width: 1200px) {
  .css-1tqs1ec { width: 50% !important; }
}

@media (min-width: 900px) {
 .TenantLoginMain { min-height: 100vh; }
 }
@media (max-width: 1400px) {
 .browser_property { grid-template-columns: 1fr 1fr; }
.imgOuter.list_rows h3 { padding: 13px; font-size: 14px; }
.text-Elipses { width: 120px; }
 }
@media (max-width: 1200px) {
 .Setting_gridBox .grd_blk .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 { max-width: 100% !important; width: 100% !important; flex-basis: 100% !important; }
.srch { display: grid !important; grid-template-columns: 34% auto; }
.searchpage .MuiGrid-grid-xs-9 { width: 100% !important; max-width: 100% !important; }
.ONBoardingStep .onboardStep.ten_en { width: 410px; }
.ONBoardingStep .onBoardDash { margin-left: 410px; width: calc(100% - 410px); }
.Agent_bgg_data .RegisterForm { margin-left: 410px; width: calc(100% - 410px); }
.Agent_bgg_data .LeftAgentAccount { width: 410px; }
/* .SettingTeam tr { grid-template-columns: 24% auto 150px 120px !important; }

*/
.slider-inner .owl-theme .owl-nav { bottom: 52%;
  justify-content: space-between;
}
}
@media (max-width: 1099px) {
 .ONBoardingStep { grid-template-columns: 412px auto !important; }
.dashboardTop { padding: 45px 30px !important; }
.Right_sidebar .test { width: 225px !important; padding: 15px; overflow-y: auto; position: fixed !important; padding-bottom: 120px !important; height: 100vh; }
.Right_sidebar .btn_gurp.reset_apply { position: fixed; width: 200px !important; bottom: 2px; right: 18px; background: #fff; padding: 10px 10px; }
.btn_gurp2>span.makeStyles-root-2 { width: 178px !important; }
.main_tenantApp { grid-template-columns: 1fr 225px; }
.browser_property { padding: 15px !important; }
 }
@media (max-width: 991px) {
 .top_bar .Search_Filters .input-group { min-width: 100px; }
.text-Elipses { width: 220px; }
.mob_hide { display: none; }
.RightAgentForgot,
  .AgentSliders,
  .RightAgentLogin,
  .RightLogin { display: none; }
.AgentLogIn { min-height: 90vh; }
.AgentForgotMain { grid-template-columns: 1fr !important; }
.AgentForgot { max-width: 400px !important; margin: auto !important; }
.ForgotForm form { margin: 0px auto !important; }
.Agent_bgg_data { grid-template-columns: 440px auto !important; }
.RegisterForm form { width: 100% !important; padding: 0 15px !important; }
.settingheader .desk_logo { display: block; width: 150px; }
.Setting .heading_section .togle-btn { margin-top: -75px; }
.settingheader h2 { padding-top: 0px; }
.Setting { padding: 25px 15px !important; }
.main_sec { width: 100% !important; }
.Property { width: 100%; padding-bottom: 270px !important; }
.footer_section { width: 250px !important; grid-gap: 38px !important; }
.footer_section span::before { width: 35px !important; }
.footer { width: 100% !important; }
.setting_imguploader img { object-fit: contain; }
/* tenent */
  .TenantLoginMain { grid-template-columns: auto !important; }
.TenantLoginMain form { width: 100% !important; }
.LogInForm.tenent_log form { margin: 39px 0; width: 100% !important; }
.UpdateVerifyOTP { grid-template-columns: auto !important; height: 100vh; padding: 30px 15px 59px 15px !important; align-content: baseline; }
.FooterAgentLogin.ten { display: none; }
.UpdateVerifyOTP .RytAgentAccount { padding: 0 !important; height: calc(100vh - 40px); display: grid !important; grid-template-columns: 100% !important; grid-template-rows: 90px auto; }
.UpdateVerifyOTP .RytAgentAccount .TopAgentLogin { height: 50px; }
.UpdateVerifyOTP .RytAgentAccount .AgentCreateAcct { padding: 0px !important; position: relative; }
.UpdateVerifyOTP .AgentCreateAcct form { margin: 0px auto !important; }
.UpdateVerifyOTP .AgentLogButton { position: fixed; bottom: 0; margin: 0 auto; width: 100%; left: 0; padding: 18px 20px; }
.UpdateVerifyOTP .AgentLogButton button { max-width: 250px; margin: auto; }
.ONBoardingStep { grid-template-columns: auto !important; }
/* .onboardStep.ten_en .onboardfoter { display: none; }
*/
  .onboardStep.ten_en { gap: 15px !important; }
.mobile_header { display: flex; }
.Right_sidebar.fr_dk .test { height: 92vh; top: 0 !important; width: 100% !important; position: relative !important; margin-top: 20px !important; }
.main_sec { height: calc(100vh - 56px) !important; }
.top_bar .Search_Filters h4 { font-size: 13px; margin: 0px; border: 1px solid #E0DEF7; border-radius: 7px; padding: 7px 11px; }
.togle-btn.filt { padding: 0px 13px !important; border: 1px solid #E0DEF7; border-radius: 7px; }
.togle-btn.filt img { width: 21px; height: 21px !important; }
/* .top_bar .Search_Filters { display: grid; grid-template-columns: auto auto 50px; grid-gap: 10px; }
*/
  .top_bar .Search_Filters { display: grid; grid-template-columns: auto 50px; grid-gap: 10px; }
.offcanvas-body .Right_sidebar .test .btn_gurp.reset_apply { position: relative; }
.Right_sidebar.fr_dk { display: none; }
.offcanvas-body .Right_sidebar.fr_dk { display: block; }
.main_tenantApp { grid-template-columns: auto; }
.top_bar { position: relative !important; padding: 11px 15px !important; }
.browser_property { margin: 0px !important; }
.offcanvas-body .sidebar.side_tenant { display: block; width: 100% !important; }
.offcanvas-body .sidebar.side_tenant .sidebar_layout { width: 100%; position: relative; }
.ONBoardingStep .onBoardDash { margin-left: 0px; width: 100% !important; }
.ONBoardingStep .onboardStep.ten_en { position: relative; width: 100%; min-height: 50px; padding-bottom: 0px; top: auto; bottom: auto; height: auto !important; }
.Agent_bgg_data .RegisterForm { margin-left: 0px; width: 100% !important; }
.Agent_bgg_data .LeftAgentAccount { position: relative; width: 100%; min-height: 50px; padding-bottom: 0px; top: auto; bottom: auto; height: auto !important; }
.offcanvas-body .Right_sidebar .test .btn_gurp.reset_apply { position: relative; padding: 22px 26px; width: 100% !; left: 0; }
.Setting_gridBox.padding_zr .grd_blk { width: 100% !important; max-width: 100% !important; margin-left: 0px !important; }
.Setting_gridBox.padding_zr .grd_blk .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-6 { padding-left: 0px; }
 }
@media (max-width: 850px) {
 .box_body { grid-template-columns: 1fr 1fr; }
 }
@media (max-width: 768px) {
 /* .Agent_account_Data { display: none !important; }
*/
  /* .creatAgentLogin { display: none !important; }
*/
  /* .LeftAgentAccount { background: #f7f7fd !important; }
*/
  .Agent_bgg_data { grid-template-columns: auto !important; }
.desklogo { display: none !important; }
.moblogo { display: block !important; }
.TopAgentLogin img { max-width: 50px !important; }
.TopAgentForgot img { max-width: 50px !important; }
.Agent_bgg_data { height: auto !important; }
.RegisterForm { padding: 10px 0 !important; }
.RegisterForm form { margin: 0 !important; }
.main-tabs button { min-width: 50px; }
.main-tabs .MuiTabs-flexContainer { gap: 6px; display: grid; grid-template-columns: auto auto auto; }
.main-tabs .MuiTabs-indicator { background-color: transparent; }
.Setting form { padding: 0 0; }
.footer { width: 100% !important; display: grid; grid-template-columns: auto; padding: 25px 31px; }
.SettingTeam { min-width: 500px !important; }
.Property.PropertyAddress .footer_section { grid-template-columns: repeat(2, auto); width: 106px !important; }
.dashboardTop form { padding: 0 !important; }
.ten_en .Agent_account_Data { display: block !important; }
/* .ten_en .Agent_account_Data { padding: 0px !important; }
*/
  .main-tabs .Mui-selected { border: 1px solid #6341E0 !important; border-radius: 4px; }
.main-tabs button { border: 1px solid #21114D; border-radius: 4px; }
.top_bar .Search_Filters { display: grid; grid-gap: 10px; }
.top_bar { padding: 6px 15px !important; }
.top_bar .Search_Filters button { padding: 2px 10px !important; font-size: 12px !important; }
.top_bar .form-control { font-size: 13px; padding: 6px 9px !important; }
.Listings.Property.t_en_ent .heading_section { grid-template-columns: auto !important; margin-bottom: 24px !important; }
.top_bar .Search_Filters .input-group { width: 100% !important; }
.PropertiesTenant .nav-pills button { position: relative; }
.btnrow { margin-top: 24px; display: flex; width: 100%; }
.ReviewProperty.Property.PropertiesTenant .nav-pills { margin: 40px 17px 0 !important; }
.PropertiesTenant .table_tab .MuiTabs-root { padding: 25px 17px 0 !important; }
.PropertiesTenant .card { padding: 15px !important; }
.PropertiesTenant form { padding: 0 15px !important; margin: 40px 0 0 !important; }
.PropertiesTenant_card { grid-template-columns: auto !important; }
.PropertiesTenant .Review_card .card-header img { height: 300px; max-height: 300px; object-fit: cover; }
.TenantreazyTop { margin-bottom: 23px; margin-top: -14px; }
.Agent_account_Data #progressbar { margin-bottom: 45px !important; margin-top: -14px !important; }
.TopAgentLogin img.profl_lg { max-width: 150px !important; }
.heading_section { padding-bottom: 15px !important; }
.MuiModal-root iframe { left: 2%; max-width: 800px; }
.Listings .heading_section { grid-template-columns: auto !important; }
.SettingTeam tr { grid-template-columns: 35px auto 100px 120px !important; min-width: 678px; }
.table thead { float: none; }
.absolute_FeaturedImg { bottom: 30px; }
/* .email-item { flex-wrap: wrap; } */
.emailupButtons { margin-left: auto; }
.landining_main_grid.story .landining_left {
  background: #ffffffe5;
  padding: 20px;
}
 }
@media (max-width:675px) {
 .modal-common { width: 90% !important; border-radius: 12px; }
 }
@media (max-width: 550px) {
 .dashlivingArangement .AddLivingArranges .MuiTabs-root.addlivingTabGurp { bottom: 143px !important; }
.leftBtn button { width: 100%; }
body .address_modal.modat_sett .modal_box { padding: 14px 10px; }
.box_body { grid-template-columns: auto; }
.box_footer .btn_grp { display: flex; width: 100%; justify-content: space-between; padding-top: 15px; }
.box_footer { flex-wrap: wrap; }
.box_footer div { width: 100%; }
.btn3.cha_t { width: 100%; }
.Setting_gridBox.grd_sz .pro_grd { grid-template-columns: auto 94px; grid-gap: 15px !important; }
/* .imgOuter.list_rows { display: grid; grid-template-columns: 1fr 1fr; bottom: -48px; border-radius: 16px; grid-gap:10px; background: transparent; }
.imgOuter.list_rows h3 { padding: 7px 13px; font-size: 15px; border: none; border-radius: 12px; border: 1px solid #e8e3e3; }
*/
  .imgOuter.list_rows { left: -8px; }
.imgOuter.list_rows h3 { padding: 13px 9px; font-size: 13px; letter-spacing: -1px; }
 }
@media (max-width: 540px) {
 .LogInForm form { padding: 0px !important; }
.AgentLogIn { width: 100% !important; }
.AgentCheckt { font-size: 14px !important; }
.AgentCheckt span { margin-right: 2px !important; }
.LeftAgentLogin { padding: 30px 15px !important; }
.ForgotForm form { padding: 0px !important; margin: 0px auto !important; }
.ForgotForm { width: 100% !important; }
.LeftAgentForgot { padding: 30px 15px !important; }
.TopAgentForgot { height: 100%; max-height: 50px; }
.MuiGrid-root.pro_grd { display: grid; max-width: 100% !important; }
.MuiGrid-root.pro_grd .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-8 { width: 100%; max-width: 100% !important; }
.form-label.for_desk { display: none; }
.pro_grd .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-4 { width: 100%; max-width: 100% !important; }
.pro_grd .up_load_ph>.MuiGrid-root { display: grid; justify-content: center; grid-gap: 19px; }
.up_load_ph .setting_imguploader { max-width: 100%; width: 100%; text-align: center; }
.up_load_ph #image-change { width: 100%; max-width: 100%; }
.up_load_ph .MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-10 { width: 100% !important; max-width: 100% !important; }
.Listings.BusinessDetails { padding: 0 0 !important; }
.Paymentmethod__headSec { display: grid !important; grid-gap: 24px; }
/* .Listings .heading_section { grid-template-columns: 47% 48% !important; }
*/
  .Listings.Team { padding: 0px !important; }
.Listings.Billing { padding: 0px !important; }
.pass_wrd .MuiGrid-grid-xs-4 { padding-left: 0px !important; }
.pass_wrd .MuiGrid-grid-xs-8 { padding-left: 0px !important; }
.Listings.Property .table_tab button { padding: 10px 8px !important; min-width: 20px !important; font-size: 12px !important; }
.css-1tqs1ec { width: 98% !important; }
form { padding: 0 15px !important; }
.ReviewProperty form { width: 100% !important; padding: 0 15px !important; }
.Verify_Code input { padding: 12px 12px !important; }
.ten_en .Agent_account_Data { padding: 0px !important; }
.onboardStep.ten_en { padding: 45px 15px; }
.dashboardTop { padding: 45px 15px !important; }
.Price_bond { grid-template-columns: auto !important; }
.searchpage .MuiGrid-grid-xs-4 { max-width: 100% !important; width: 100% !important; flex-basis: 100% !important; }
.searchpage .MuiGrid-grid-xs-8 { max-width: 100% !important; width: 100% !important; flex-basis: 100% !important; }
.srch { grid-template-columns: auto !important; }
.searchpage .MuiGrid-spacing-xs-2.MuiGrid-grid-xs-9 { width: 100% !important; max-width: 100% !important; margin: 0 !important; }
.searchpage .MuiGrid-grid-xs-4 { padding-left: 0px !important; }
.searchpage .MuiGrid-grid-xs-8 { padding-left: 0px !important; }
.searchpage .MuiGrid-grid-xs-12 { padding-left: 0px !important; }
.searchpage form { padding: 0 0px !important; }
.my_profile.t_ent form { padding: 0px !important; }
.browser_property { grid-template-columns: 100% !important; }
.ApplicationModal .section_appli { grid-template-columns: auto auto !important; }
.ApplicationModal .card-header img { width: 50px; }
.Setting_gridBox .MuiGrid-grid-xs-6 { max-width: 100%; }
.footerl_inner p.copyrt {
  display: grid;
}
.ft_menu {display: grid;}
.listUsername { min-width: auto !important; display: grid; width: auto;text-align: center;}
.listUsername span {
  word-break: break-all;
  word-wrap: anywhere;
  white-space: break-spaces;
  text-align: center; margin: auto !important;
}
.emailupButtons div svg {
  margin: auto;
}
.emailupButtons div {
  display: grid;text-align: center;
}
.emailupButtons .btn {
  padding: 1px 6px !important;
  border-radius: 20px !important;
  width: 66px;
}
.setting_imguploader {
  text-align: center;
}
.contact_form {
  padding: 20px;
}
 }
@media (max-width: 480px) {
 .AgentLogIn { min-height: 50vh; }
.LeftAgentLogin { padding-bottom: 53px !important; }
.FooterAgentLogin { position: fixed; bottom: -3px; width: 100%; background: #f7f7fd; padding: 15px 0 15px 0; display: none; }
.FooterAgentForgot { display: none; }
.AgentLogIn { padding: 7px 0px 7px 0px !important; }
.ForgotForm { width: 100% !important; height: 100%; padding: 0 0; position: relative; }
.ForgotForm form { height: 100%; }
.ForgotForm .AgentLogButton { margin: 0 auto !important; position: absolute; bottom: 0; }
.modal_view .btn_gurpModal { grid-template-columns: auto !important; grid-gap: 6px !important; margin: 11px 0 !important; }
.modal_scroll { height: calc(100vh - 166px) !important; }
.modal_scroll .padding_modal { padding: 0 15px !important; }
.PropertyAddress .City_sec { grid-template-columns: auto !important; grid-gap: 7px !important; }
.Review_card .card_info p { font-size: 12px !important; }
/* .Listings.Property .heading_section { grid-template-columns: 1fr auto !important; }
*/
  .UpdateVerifyOTP .AgentLogButton button { max-width: 500px; }
/* .top_bar .Search_Filters .input-group { display: none; }
*/
  .top_bar .Search_Filters { grid-template-columns: auto 50px; }
 }
@media (max-width: 400px) {
 .btn_grp .btn3 { padding-left: 8px !important; padding-right: 8px !important; letter-spacing: 0px; white-space: nowrap; }
 }



 .bankProduct {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  align-items: center;
}
.pdf-preview{
  margin-top: 15px;
}

.pdf-preview img {
  cursor: pointer;
  transition: transform 0.2s;
  margin-right:10px;
  width:30px;
  height:30px
}

.pdf-preview img:hover {
  transform: scale(1.1);
}
