@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Rajdhani:wght@300;400;500;600&display=swap');

* {font-family: 'Inter';font-style: normal;margin: 0;padding: 0;text-decoration: none;}
a.active,
a:hover {color: #6341E0;background: #F7F7FD;border-radius: 6px;}
.sidebar .btn-primary {width: 100%;background: #6341E0;border: 1px solid #6341E0 ;border-radius: 8px;font-weight: 600;font-size: 14px;line-height: 20px;
  color: #FFFFFF;padding: 8px 14px;cursor: pointer;text-transform: capitalize;}
  .sidebar .btn-primary:hover{color: #6341E0 !important;background: #F7F7FD !important;border: 1px solid #6341E0 !important;border-radius: 8px;}

.sidebar {position: relative;width: 280px;float: left;}
.sidebar_sec {color: #adadad;text-align: left;transition: width, left, right, 0.3s;position: relative;z-index: 1009;
    height: 100vh;width: 100%;min-width: 100%;background-color: white;box-shadow: 0.5px 0.866px 2px 0px rgb(0 0 0 / 15%);}
/* .sidebar_layout {height: 100%;overflow-x: hidden;position: relative;display: flex;flex-direction: column;z-index: 101;overflow-y: hidden;} */
.sidebar header {border: none;margin: 30px 24px;}
.sidebar a {font-weight: 600;font-size: 16px;line-height: 24px;color: #21114D !important;transition: all .5s ease;align-items: center;
  padding: 9px 12px;gap: 15px;margin: 8px 24px;display: flex;width: auto;cursor: pointer;}
  .sidebar a.active,
.sidebar a:hover {color: #6341E0 !important;background: #F7F7FD;border-radius: 6px;}
.sidebar a i {font-size: 23px;}


.Purchase_Credits {background: #F7F7FD;border: 1px solid #E0DEF7;border-radius: 8px;padding: 20px 16px;margin: 24px 20px;}
.Reazy_Credits {display: flex;gap: 6px;align-items: center;}
.Purchase_Credits p{margin: 12px 0;font-weight: 600;font-size: 14px;line-height: 20px;color: #6341E0;}
.user_details {border-top: 1px solid #E0DEF7;padding:30px 10px 30px 24px;display: grid;grid-template-columns: auto 1fr auto;
  align-items: center;grid-gap: 10px;}
.user_details h5 span{font-weight: 400;}
.user_details img {height: 40px; width: 40px; border-radius: 50%;}
.sidebar_content{    flex-grow: 1;}



.sidebar_layout {
  height: 100%;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 101;
  /* overflow-y: hidden; */
  background-color: white;
  box-shadow: 0.5px 0.866px 2px 0px rgb(0 0 0 / 15%);
  position: fixed;
  min-width: 280px;max-width: 280px;
}
.user_details h5 {
  line-height: 16px;
  width: 100%;
  word-break: break-all;
  font-size: 16px;
}
.user_details h5 p {
  margin-top: 7px;
}
.Reazy_Credits h5 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #21114D;
}
.Reazy_Credits h5 span{
  background: #6341E0;
  mix-blend-mode: normal;
  border-radius: 16px;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #FFFFFF;
  padding: 2px 6px;
}

@media screen and (max-width: 991px) {
  .sidebar {
display: none;
}

.sidebar.agent-toggleside{
  display: block;
  width: 100%;
  float: none;
}
.sidebar.agent-toggleside .sidebar_layout {
  position: inherit;
  min-width: auto;
  box-shadow: 0 0 0 0;
}

.sidebar.agent-toggleside .sidebar_sec {
  box-shadow: 0 0 0 0;
}
.offcanvas-header {
  justify-content: right;
}
}


